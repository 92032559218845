import {useEffect, useState} from 'react'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import useAllTypes from '../data/useAllTypes'
import ObjectUtils from '../../functions/object.functions'

const useServiceTicketIssueLocationFilter = (initialValue?: number[]) => {
  const allTypes = useAllTypes()

  const [locations, setLocations] = useState<CheckboxItem[]>([])

  useEffect(() => {
    const locations = ObjectUtils.values(allTypes.value.serviceTicketIssueLocation.byId)

    setLocations(
      locations.map(item => ({
        id: Number(item?.id),
        name: item?.description || '',
        checked: !!initialValue?.includes(Number(item?.id)),
      })),
    )
  }, [allTypes.value.serviceTicketIssueLocation.byId])

  return [locations, setLocations] as const
}

export default useServiceTicketIssueLocationFilter
