// layouts/DeveloperGuide/DeveloperGuide.tsx

import './DeveloperGuide.style.scss'
import Sidebar from '../../components/Sidebar'
import DeveloperMenu from '../../components/Sidebar/SidebarMenuDeveloper'
import {Outlet} from 'react-router-dom'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'

type Props = {
  children?: React.ReactNode
}

const DeveloperGuideLayout = ({children}: Props) => {
  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'developer-guide'} data-testid={'DeveloperGuideLayout'}>
        <Sidebar menu={<DeveloperMenu />}></Sidebar>
        <div className={'app-body'}>
          <Outlet />
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default DeveloperGuideLayout
