// Button.tsx

import React, {FC} from 'react'
import './Button.style.scss'
import {IconOptions} from '../Icon/Icons.d'
import Icon from '../Icon'
import {Link} from 'react-router-dom'

export type ButtonThemeOptions =
  | 'default'
  | 'primary'
  | 'outline'
  | 'primary-outline'
  | 'secondary-outline'
  | 'secondary'
  | 'info'
  | 'success'
  | 'success-outline'
  | 'warning'
  | 'danger'
  | 'danger-outline'
  | 'white'
  | 'white-outline'
  | 'link'
  | 'control'
  | 'control-black'
  | 'contrast'

export type ButtonSizeOptions = 'xs' | 'sm' | 'md' | 'lg'

export type ButtonProps = {
  fontStyle?: string
  id?: string
  capitalize?: boolean
  theme?: ButtonThemeOptions
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  size?: ButtonSizeOptions
  width?: 'narrow' | 'standard' | 'wide' | 'block' | 'square'
  icon?: IconOptions
  iconPosition?: 'left' | 'right' | null
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  to?: string
  children?: React.ReactNode
  className?: string
  testId?: string
  loading?: boolean
}

const defaultProps: ButtonProps = {
  theme: 'default',
  disabled: false,
  type: 'button',
  size: 'md',
  width: 'standard',
  iconPosition: null,
  className: '',
  testId: 'Button',
}

const Button: FC<ButtonProps> = (props: ButtonProps) => {
  props = {...defaultProps, ...props}

  const disabledClass = props.disabled ? 'disabled' : ''
  const iconPositionClass =
    (props.icon && props.children === undefined ? 'icon' : '') +
    (props.iconPosition ? ' icon-' + props.iconPosition : '')

  if (props.to) {
    return (
      <Link
        id={props.id}
        className={`btn btn-${props.theme} btn-${props.size} btn-${props.width} ${disabledClass} ${iconPositionClass}  ${props.fontStyle} ${props.className}`}
        data-testid={props.testId}
        type={props.type}
        to={props.to}
      >
        {props.icon && props.iconPosition !== 'right' && <Icon icon={props.icon} />}
        {props.loading ? 'Loading ...' : props.children}
        {props.icon && props.iconPosition === 'right' && <Icon icon={props.icon} />}
      </Link>
    )
  }

  return (
    <button
      id={props.id}
      type={props.type}
      className={`btn ${props.capitalize ? 'btn-capitalize' : ''} btn-${
        props.theme
      } btn-${props.size} btn-${props.width} ${disabledClass} ${iconPositionClass} ${
        props.fontStyle
      } ${props.className}`}
      data-testid={props.testId}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.icon && props.iconPosition !== 'right' && <Icon icon={props.icon} />}
      {props.loading ? 'Loading ...' : props.children}
      {props.icon && props.iconPosition === 'right' && <Icon icon={props.icon} />}
    </button>
  )
}

export default React.memo(Button)
