import {createContext, FC, useEffect, useState, useMemo} from 'react'
import {TPersonProfile} from '../data/graphql/queries/entities'
import LocalStorageUtils from '../functions/localStorage.functions'

export type User = {
  firstName: string
  lastName: string
  email: string
}

type AuthContextData = {
  user: User | null
  personProfile: TPersonProfile | null
  setUser: (value: User | null) => void
  setPersonProfile: (value: TPersonProfile | null) => void
}

export const AuthContext = createContext<AuthContextData>({
  user: null,
  personProfile: null,
  setUser: () => {
    //
  },
  setPersonProfile: () => {
    //
  },
})

interface IProps {
  children: React.ReactNode
}

const AuthContextProvider: FC<IProps> = props => {
  const [user, setUser] = useState(LocalStorageUtils.getItem('user'))
  const [personProfile, setPersonProfile] = useState(
    LocalStorageUtils.getItem('personProfile'),
  )

  useEffect(() => {
    LocalStorageUtils.setItem('user', user)

    if (!user?.email) {
      setPersonProfile(null)
      LocalStorageUtils.setItem('devMode', false)

      LocalStorageUtils.removeItem('personTypes')
      LocalStorageUtils.removeItem('accessTypes')
      LocalStorageUtils.removeItem('residentTypes')
      LocalStorageUtils.removeItem('leaseTypes')
      LocalStorageUtils.removeItem('unitTypes')
      LocalStorageUtils.removeItem('secretTypes')

      LocalStorageUtils.removeItem('deviceTypes')
      LocalStorageUtils.removeItem('deviceClassTypes')
      LocalStorageUtils.removeItem('deviceLocationTypes')
      LocalStorageUtils.removeItem('deviceManufacturerTypes')
    }
  }, [user])

  useEffect(() => {
    LocalStorageUtils.setItem('personProfile', personProfile)
  }, [personProfile])

  const contextValue = useMemo(() => {
    return {
      user,
      personProfile,
      setUser,
      setPersonProfile,
    }
  }, [user, personProfile])

  return (
    <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
  )
}

export default AuthContextProvider
