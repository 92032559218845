import {ApolloClient, createHttpLink, from, InMemoryCache, split} from '@apollo/client'
import {getAuth} from 'firebase/auth'
import {setContext} from '@apollo/client/link/context'
import {onError} from '@apollo/client/link/error'

import {getMainDefinition} from '@apollo/client/utilities'
import {wsLink} from './links/wsLink'
import {errorFromBodyLink} from './links/errorFromBodyLink'
import {personProfileIdLink} from './links/personProfileIdLink'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
})

const errorLink = onError(({graphQLErrors, networkError}) => {
  console.log('graphQLErrors', graphQLErrors)
  console.log('networkError', networkError)

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err?.extensions?.code) {
        default:
        // console.log(err)
      }
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const authLink = setContext(async (request, {headers}) => {
  const auth = getAuth()
  const token = await auth?.currentUser?.getIdToken()

  return {
    headers: {
      ...headers,
      'apthub-auth-token': `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    },
  }
})

const splitLink = split(
  ({query}) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink,
)

const readAsNumber = {
  read(existing: unknown) {
    const isNumber = typeof existing === 'number'
    const isString = typeof existing === 'string'

    return isNumber || isString ? Number(existing) : null
  },
}

export const client = new ApolloClient({
  link: from([authLink, personProfileIdLink, errorFromBodyLink, errorLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      TransactionalDbQueries: {
        merge: true,
      },
      UtilityQueries: {
        merge: true,
      },

      PersonType: {
        fields: {
          personTypeId: readAsNumber,
        },
      },
      ResidentType: {
        fields: {
          residentTypeId: readAsNumber,
        },
      },
      PersonAccessType: {
        fields: {
          personAccessTypeId: readAsNumber,
        },
      },

      LeaseType: {
        fields: {
          leaseTypeId: readAsNumber,
        },
      },
      SecretType: {
        fields: {
          secretTypeId: readAsNumber,
        },
      },
      UnitType: {
        fields: {
          unitTypeid: readAsNumber,
        },
      },

      ServiceTicketStatusType: {
        fields: {
          serviceTicketStatusTypeId: readAsNumber,
        },
      },
      ServiceTicketCategoryType: {
        fields: {
          serviceTicketCategoryTypeId: readAsNumber,
        },
      },
      ServiceTicketIssueLocationType: {
        fields: {
          serviceTicketIssueLocationTypeId: readAsNumber,
        },
      },

      DeviceManufacturerType: {
        fields: {
          deviceManufacturerTypeId: readAsNumber,
        },
      },
      DeviceClassType: {
        fields: {
          deviceClassTypeId: readAsNumber,
        },
      },
      DeviceLocationType: {
        fields: {
          deviceLocationTypeId: readAsNumber,
        },
      },
      DeviceType: {
        fields: {
          deviceTypeId: readAsNumber,
        },
      },
    },
  }),
})
