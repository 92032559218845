import {useQuery} from '@apollo/client'
import {GET_ALL_TENANTS} from '../../data/graphql/queries/common'
import {
  TGetAllTenantsResponse,
  TGetAllTenantsVariables,
} from '../../data/graphql/queries/common/types'
import useCurrentProfile from '../useCurrentProfile'

const useAllTenants = () => {
  const {isDevAdmin, tenantUuid} = useCurrentProfile()

  const query = useQuery<TGetAllTenantsResponse, TGetAllTenantsVariables>(
    GET_ALL_TENANTS,
    {
      variables: {
        condition: {
          isActive: true,
          isDeleted: false,
          tenantUuid: isDevAdmin ? undefined : tenantUuid,
        },
      },
    },
  )

  const data = query.data?.db.tenants.nodes

  return {
    query,
    data,
    loading: query.loading,
  }
}

export default useAllTenants
