import {gql} from '@apollo/client'

export const SEND_APP_INVITE = gql`
  mutation SendAppInvitation($input: SendAppInvitationInput!) {
    utility {
      sendAppInvitation(input: $input) {
        ... on SendInvitationSuccess {
          success
        }
        ... on CommonError {
          status
          source
          message
          code
        }
      }
    }
  }
`

export const GET_TENANT_SECRETS = gql`
  query AllTenantSecrets($condition: TenantSecretCondition) {
    db: transactionalDb {
      secrets: allTenantSecrets(condition: $condition) {
        nodes {
          secretType: secretTypeBySecretTypeId {
            id: secretTypeId
            code
          }
        }
      }
    }
  }
`
