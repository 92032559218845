import {gql} from '@apollo/client'

export const GET_INFO_FOR_LOGIN = gql`
  query GetInfoForLogin($input: GetInfoForLoginInput!) {
    utility {
      getInfoForLogin(input: $input) {
        ... on GetInfoForLoginSuccess {
          idpTenantId
          personId
          profiles {
            personProfileId
            propertyId
            personTypeId
            residentTypeId
            personTypeCode
          }
        }
        ... on CommonError {
          code
          message
          source
          status
        }
      }
    }
  }
`

export const UPDATE_PERSON_BY_ID = gql`
  mutation UpdatePersonById($input: UpdatePersonByPersonIdInput!) {
    transactionalDb {
      updatePersonByPersonId(input: $input) {
        person {
          firstName
          lastName
          email
        }
      }
    }
  }
`
