import {useCallback, useEffect, useMemo, useState} from 'react'
import './StaffOverview.style.scss'
import {capitalize} from '../../functions'
import Badge from '../../components/Badge'
import {useNavigate, useParams} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/client'
import {GET_EMPLOYEE} from '../../data/graphql/queries/people'
import {
  TGetEmployeeResponse,
  TGetEmployeeVariables,
} from '../../data/graphql/queries/people/types'
import useScheduleAccessPoints, {
  TServiceCommonAreaAccess,
} from '../../layouts/VendorUserLayout/useScheduleAccessPoints'
import useToast from '../../hooks/useToast'
import {
  TSendAppInviteResponse,
  TSendAppInviteVariables,
} from '../../data/graphql/queries/utility/types'
import {SEND_APP_INVITE} from '../../data/graphql/queries/utility'
import useLockAccesses from '../../hooks/useLockAccesses'
import {TAccessPointsValue} from '../../layouts/VendorUserLayout/useAccessPoints'
import {TAccessScheduleFields} from '../../components/AccessSchedule/AccessSchedule'
import {PersonTypeCodeEnum, ResidentTypeCodeEnum} from '../../data/graphql/queries/enums'
import usePersonTypes from '../../hooks/types/usePersonTypes'
import useCurrentProfile from '../../hooks/useCurrentProfile'

type TLockAccess = {
  lockId: string
  installedDeviceId: number
  schedule?: string
}

interface IAsignedProperty {
  name: string
  access: JSX.Element
  manager: string
  city: string
  state: string
  zip: string
  residents: number
  staff: number
}

interface IStaffInfo {
  personId: string
  name: string
  position: string
  isActive: boolean
  email: string
  hireDate: string
}

const useStaffOverview = () => {
  const {showToast, showErrorToast} = useToast()
  const navigate = useNavigate()
  const {staffId} = useParams()
  const {PersonTypeIds} = usePersonTypes()
  const currentProfile = useCurrentProfile()

  const [staffInfo, setStaffInfo] = useState<IStaffInfo | null>(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [locksAccesses, setLocksAccesses] = useState<TLockAccess[]>([])
  const [commonAreaAccesses, setCommonAreaAccesses] =
    useState<TServiceCommonAreaAccess | null>(null)

  const response = useQuery<TGetEmployeeResponse, TGetEmployeeVariables>(GET_EMPLOYEE, {
    skip: !staffId,
    variables: {
      personEmployeeId: staffId ? +staffId : 0,
      managersCondition: {
        isActive: true,
        isDeleted: false,
        departmentCode: 'MGR',
      },
      residentsCondition: {
        isActive: true,
        isDeleted: false,
        personAccessTypeId: 2,
      },
      profilesCondition: {
        isDeleted: false,
      },
    },
  })

  const [sendAppInvite, inviteResponse] = useMutation<
    TSendAppInviteResponse,
    TSendAppInviteVariables
  >(SEND_APP_INVITE, {
    onCompleted: () => {
      showToast({
        title: 'Employee has been invited',
        message: `${staffInfo?.name} will receive the notification`,
        type: 'info',
      })
    },
  })

  const employee = response.data?.transactionalDb?.employee
  const personId = employee ? Number(employee?.personId) : 0
  const yaleUserId = employee?.person?.miscInfo?.yaleLock?.userId
  const isIdentityCreated = employee?.person?.isIdentityCreated

  const employeeProfile = useMemo(() => {
    const profiles = employee?.person?.profiles.nodes

    if (!profiles) {
      return ''
    }

    const devAdminProfile = profiles.find(
      ({personTypeId}) => +personTypeId === PersonTypeIds[PersonTypeCodeEnum.DEV_ADMIN],
    )

    if (currentProfile.isDevAdmin && devAdminProfile) {
      return devAdminProfile.personProfileId
    }

    const employeeProfile = profiles.find(
      ({personTypeId}) => +personTypeId === PersonTypeIds[PersonTypeCodeEnum.EMPLOYEE],
    )

    if (employeeProfile) {
      return employeeProfile.personProfileId
    }

    return ''
  }, [PersonTypeIds, employee?.person?.profiles.nodes, currentProfile.isDevAdmin])

  const {
    updateAppAccesses,
    updatePinAccesses,
    revokeLockAccesses,
    grantCommonAreaAccesses,
    revokeCommonAreaAccesses,
    refreshPersonAccesses,
    loading,
  } = useLockAccesses({
    profileId: employeeProfile ? Number(employeeProfile) : 0,
    personType: PersonTypeCodeEnum.EMPLOYEE,
    personId,
  })

  const accessPoints = useScheduleAccessPoints({
    personId,
    profileId: employeeProfile,
  })

  useEffect(() => {
    if (!employee?.person) {
      return
    }

    setStaffInfo({
      personId: employee.personId || '0',
      name: capitalize(`${employee.person.firstName} ${employee.person.lastName}`),
      position: employee.departmentDescription || '',
      isActive: employee.isActive,
      hireDate: employee.hireDate || '',
      email: employee.workEmail || '',
    })
  }, [employee])

  const properties = useMemo<IAsignedProperty[]>(() => {
    const property = employee?.property
    const manager = property?.manager.nodes[0]?.person

    if (!property) {
      return []
    }

    return [
      {
        name: property.propertyName,
        access: (
          <Badge theme={employee.isActive ? 'info' : 'default'} size={'sm'}>
            {employee.isActive ? 'Active' : 'Inactive'}
          </Badge>
        ),
        manager: manager ? capitalize(`${manager.firstName} ${manager.lastName}`) : '—',
        city: property.address.city,
        state: property.address.state.code,
        zip: property.address.zipCode,
        residents: property.residents.totalCount,
        staff: property.staff.totalCount,
      },
    ]
  }, [employee?.property, employee?.isActive])

  const onPressInvite = async () => {
    let accesses: Awaited<ReturnType<typeof accessPoints.submitForm>>

    try {
      accesses = await accessPoints.submitForm()
    } catch (e) {
      return showErrorToast(
        'Failed to parse access data',
        'Please, refresh the page and try again',
      )
    }

    const hasAccessToChange =
      accesses.commonAreaPropertyAccessDeleted ||
      accesses.commonAreaPropertyAccessRequested ||
      accesses.lockAccessesDeleted.length ||
      accesses.lockAccessesRequested.length

    if (!hasAccessToChange) {
      return showErrorToast('Access update', 'No access changes have been detected')
    }

    if (!employee?.person?.mobilePhone) {
      setLocksAccesses(accesses.lockAccessesRequested)
      setCommonAreaAccesses(accesses.commonAreaPropertyAccessRequested)
      setModalOpen(true)

      return
    }

    onSubmit({
      phone: employee?.person?.mobilePhone,
      lockAccessesRequested: accesses.lockAccessesRequested,
      lockAccessesDeleted: accesses.lockAccessesDeleted,
      commonAreaAccessDeleted: accesses.commonAreaPropertyAccessDeleted,
      commonAreaAccessRequested: accesses.commonAreaPropertyAccessRequested,
    })
  }

  const handleUserInvite = async (
    phoneNumber: string,
    requestedAccesses: TLockAccess[],
    requestedCommonAreaAccess: TServiceCommonAreaAccess | null,
  ) => {
    try {
      if (!employee?.person || !phoneNumber) {
        throw new Error('Invalid data')
      }

      const payload: TSendAppInviteVariables = {
        input: {
          sender: {
            lastName: 'Platform',
            firstName: 'Apthub',
          },
          invitee: {
            email: employee.workEmail || '',
            firstName: employee.person.firstName,
            lastName: employee.person.lastName,
            mobilePhone: phoneNumber,
            personType: 'EMPLOYEE',
          },
          requestedThermostatAccesses: [],
          requestedLockAccesses: requestedAccesses.map(
            ({schedule, installedDeviceId}) => ({
              installedDeviceId,
              schedule,
            }),
          ),
        },
      }

      if (requestedCommonAreaAccess) {
        payload.input.requestedCommonAreaLockAccess = {
          propertyId: Number(requestedCommonAreaAccess.propertyId),
        }
      }

      await sendAppInvite({
        variables: payload,
      })
    } catch (e) {
      showErrorToast('Invite failure', 'Failed to send user invite')
    }
  }

  const onSubmit = async (values: {
    phone: string
    lockAccessesRequested?: TLockAccess[]
    lockAccessesDeleted?: TLockAccess[]
    commonAreaAccessRequested?: TServiceCommonAreaAccess | null
    commonAreaAccessDeleted?: string
  }) => {
    const requestedAccesses = values.lockAccessesRequested || locksAccesses
    const requestedCommonAreaAccess =
      values.commonAreaAccessRequested || commonAreaAccesses

    if (!isIdentityCreated || !yaleUserId) {
      return handleUserInvite(values.phone, requestedAccesses, requestedCommonAreaAccess)
    }

    await Promise.allSettled([
      revokeLockAccesses(values.lockAccessesDeleted),
      revokeCommonAreaAccesses(values.commonAreaAccessDeleted || ''),
    ])

    const [appAccessesResponse] = await Promise.allSettled([
      updateAppAccesses({
        accesses: requestedAccesses,
        personType: PersonTypeCodeEnum.EMPLOYEE,
        residentType: ResidentTypeCodeEnum.NOT_A_RESIDENT,
      }),
      grantCommonAreaAccesses(requestedCommonAreaAccess),
    ])

    if (appAccessesResponse.status === 'fulfilled') {
      const failedLocks = appAccessesResponse.value

      await updatePinAccesses(
        requestedAccesses.filter(({lockId}) => !failedLocks.includes(lockId)),
      )
    }

    await refreshPersonAccesses()

    setModalOpen(false)
  }

  const onPressActivity = () => {
    navigate(
      encodeURI(
        `/activity-logs/locks?limit=10&page=1&orderBy=TIME_STAMP_ASC&searchTerm=${staffInfo?.name}`,
      ),
    )
  }

  const onChangeAccessPoint = (value: TAccessPointsValue<TAccessScheduleFields>) => {
    accessPoints.setFieldValue('accessPoints', value)
  }

  const onCloseModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  return {
    profileId: employeeProfile,

    loading,
    properties,
    staffInfo,
    employee,
    accessPoints,
    inviteLoading: inviteResponse.loading,
    isInviteModalOpen: modalOpen,

    onPressInvite,
    handleUserInvite,
    onCloseModal,
    onSubmit,
    onPressActivity,
    onChangeAccessPoint,
  }
}

export default useStaffOverview
