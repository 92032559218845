import {useMemo} from 'react'
import LocalStorageUtils from '../../functions/localStorage.functions'
import {LeaseTypeCodeEnum} from '../../data/graphql/queries/enums'

const useLeaseTypes = () => {
  const LeaseTypeIds = useMemo<Record<LeaseTypeCodeEnum, number>>(() => {
    const types = LocalStorageUtils.getItem('leaseTypes')

    const getId = (code: LeaseTypeCodeEnum) => {
      return types?.byCode[code]?.id || -1
    }

    return {
      [LeaseTypeCodeEnum.UNKNOWN]: getId(LeaseTypeCodeEnum.UNKNOWN),
      [LeaseTypeCodeEnum.FIRST_LEASE]: getId(LeaseTypeCodeEnum.FIRST_LEASE),
      [LeaseTypeCodeEnum.MOVED_OUT]: getId(LeaseTypeCodeEnum.MOVED_OUT),
      [LeaseTypeCodeEnum.NOTICE_GIVEN]: getId(LeaseTypeCodeEnum.NOTICE_GIVEN),
      [LeaseTypeCodeEnum.RENEWAL]: getId(LeaseTypeCodeEnum.RENEWAL),
    }
  }, [])

  return {LeaseTypeIds}
}

export default useLeaseTypes
