import {CheckboxItem} from '../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import {DeviceClassTypeCodeEnum} from '../data/graphql/queries/enums'
import TypesUtils from './types.function'

export type TDeviceItem = {
  device: {
    code: string
    typeId: string
    description: string
    classTypeId: string
  }
  location: {
    code: string
    typeId: string
    description: string
  }
}

export type TDeviceCheckboxItem = TDeviceItem &
  CheckboxItem & {
    disabled: boolean
  }

export type TGroupedDevices<T extends TDeviceItem> = {
  locks: T[]
  thermostats: T[]
}

export const flatDevices = <T extends TDeviceItem>(
  devices?: TGroupedDevices<T> | null,
) => {
  return Object.values(devices || {}).reduce(
    (result, devicesGroup) => [...result, ...devicesGroup],
    [],
  )
}

export const groupDevices = <T extends TDeviceItem>(devices: T[]): TGroupedDevices<T> => {
  const lockOptions: Record<string, T> = {}
  const thermostatOptions: Record<string, T> = {}

  const [lockClassTypeId, thermostatClassTypeId] = TypesUtils.deviceClass.idsByCodes([
    DeviceClassTypeCodeEnum.LOCK,
    DeviceClassTypeCodeEnum.THERMOSTAT,
  ])

  devices.forEach(item => {
    if (+item.device.classTypeId === lockClassTypeId) {
      lockOptions[item.location.typeId] = item
    } else if (+item.device.classTypeId === thermostatClassTypeId) {
      thermostatOptions[item.location.typeId] = item
    }
  })

  return {
    locks: Object.values(lockOptions),
    thermostats: Object.values(thermostatOptions),
  }
}

export const getDeviceItemCheckboxId = (classTypeId: string, locationTypeId: string) => {
  return `${classTypeId}:${locationTypeId}`
}

export const isCommonAreaBuilding = (building?: {buildingName: string} | null) => {
  return building?.buildingName.toLowerCase() === 'common area'
}
