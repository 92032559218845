import {User} from '../contexts/AuthContext'
import {
  TAccessType,
  TDeviceClassType,
  TDeviceLocationType,
  TDeviceManufacturerType,
  TDeviceType,
  TLeaseType,
  TPersonType,
  TResidentType,
  TSecretType,
  TServiceTicketCategoryType,
  TServiceTicketIssueLocationType,
  TUnitType,
} from '../data/graphql/queries/common/types'
import {TPersonProfile} from '../data/graphql/queries/entities'
import {
  AccessTypeCodeEnum,
  DeviceClassTypeCodeEnum,
  DeviceManufacturerCodeEnum,
  DeviceTypeCodeEnum,
  LeaseTypeCodeEnum,
  PersonTypeCodeEnum,
  ResidentTypeCodeEnum,
  SecretTypeCodeEnum,
  UnitTypeCodeEnum,
} from '../data/graphql/queries/enums'
import {TypeRecord} from '../hooks/data/useAllTypes'

type Storage = {
  devMode: boolean | null
  user: User | null
  personProfile: TPersonProfile | null
  personTypes: TypeRecord<number, PersonTypeCodeEnum, TPersonType>
  accessTypes: TypeRecord<number, AccessTypeCodeEnum, TAccessType>
  residentTypes: TypeRecord<number, ResidentTypeCodeEnum, TResidentType>
  leaseTypes: TypeRecord<number, LeaseTypeCodeEnum, TLeaseType>
  unitTypes: TypeRecord<number, UnitTypeCodeEnum, TUnitType>
  secretTypes: TypeRecord<number, SecretTypeCodeEnum, TSecretType>

  serviceTicketCategoryTypes: TypeRecord<number, string, TServiceTicketCategoryType>
  serviceTicketIssueLocationTypes: TypeRecord<
    number,
    string,
    TServiceTicketIssueLocationType
  >

  deviceTypes: TypeRecord<number, DeviceTypeCodeEnum, TDeviceType>
  deviceClassTypes: TypeRecord<number, DeviceClassTypeCodeEnum, TDeviceClassType>
  deviceLocationTypes: TypeRecord<number, string, TDeviceLocationType>
  deviceManufacturerTypes: TypeRecord<
    number,
    DeviceManufacturerCodeEnum,
    TDeviceManufacturerType
  >
}

const LocalStorageUtils = {
  getItem<K extends keyof Storage>(key: K): Storage[K] | null {
    const data = localStorage.getItem(key)

    if (typeof data !== 'string') {
      return null
    }

    try {
      const result = JSON.parse(data)

      return result
    } catch (e) {
      return null
    }
  },

  setItem<K extends keyof Storage>(key: K, data: Storage[K]) {
    localStorage.setItem(key, JSON.stringify(data))
  },

  removeItem<K extends keyof Storage>(key: K) {
    localStorage.removeItem(key)
  },
}

export default LocalStorageUtils
