import {useEffect, useState} from 'react'
import {useLazyQuery} from '@apollo/client'
import {
  TAccessType,
  TAllTypesResponse,
  TDeviceClassType,
  TDeviceLocationType,
  TDeviceManufacturerType,
  TDeviceType,
  TLeaseType,
  TPersonType,
  TResidentType,
  TSecretType,
  TServiceTicketCategoryType,
  TServiceTicketIssueLocationType,
  TUnitType,
} from '../../data/graphql/queries/common/types'
import {GET_ALL_TYPES} from '../../data/graphql/queries/common'
import {arrayToObject} from '../../functions/array.functions'
import {
  AccessTypeCodeEnum,
  DeviceClassTypeCodeEnum,
  DeviceManufacturerCodeEnum,
  DeviceTypeCodeEnum,
  LeaseTypeCodeEnum,
  PersonTypeCodeEnum,
  ResidentTypeCodeEnum,
  SecretTypeCodeEnum,
  UnitTypeCodeEnum,
} from '../../data/graphql/queries/enums'
import LocalStorageUtils from '../../functions/localStorage.functions'

export type TypeRecord<Id extends string | number, Code extends string, Value> = {
  byId: Partial<Record<Id, Value>>
  byCode: Partial<Record<Code, Value>>
}

type TValue = {
  person: TypeRecord<number, PersonTypeCodeEnum, TPersonType>
  access: TypeRecord<number, AccessTypeCodeEnum, TAccessType>
  resident: TypeRecord<number, ResidentTypeCodeEnum, TResidentType>
  lease: TypeRecord<number, LeaseTypeCodeEnum, TLeaseType>
  unit: TypeRecord<number, UnitTypeCodeEnum, TUnitType>
  secret: TypeRecord<number, SecretTypeCodeEnum, TSecretType>

  serviceTicketCategory: TypeRecord<number, string, TServiceTicketCategoryType>
  serviceTicketIssueLocation: TypeRecord<number, string, TServiceTicketIssueLocationType>

  device: TypeRecord<number, DeviceTypeCodeEnum, TDeviceType>
  deviceClass: TypeRecord<number, DeviceClassTypeCodeEnum, TDeviceClassType>
  deviceLocation: TypeRecord<number, string, TDeviceLocationType>
  deviceManufacturer: TypeRecord<
    number,
    DeviceManufacturerCodeEnum,
    TDeviceManufacturerType
  >
}

const defaultValue: TValue = {
  person: {
    byId: {},
    byCode: {},
  },
  access: {
    byId: {},
    byCode: {},
  },
  resident: {
    byId: {},
    byCode: {},
  },
  lease: {
    byId: {},
    byCode: {},
  },
  unit: {
    byId: {},
    byCode: {},
  },
  secret: {
    byId: {},
    byCode: {},
  },

  serviceTicketCategory: {
    byId: {},
    byCode: {},
  },
  serviceTicketIssueLocation: {
    byId: {},
    byCode: {},
  },

  device: {
    byId: {},
    byCode: {},
  },
  deviceClass: {
    byId: {},
    byCode: {},
  },
  deviceLocation: {
    byId: {},
    byCode: {},
  },
  deviceManufacturer: {
    byId: {},
    byCode: {},
  },
}

type Props = {
  autoFetch?: boolean
  updateCache?: boolean
}

const useAllTypes = ({autoFetch, updateCache}: Props = {autoFetch: true}) => {
  const [fetchAllTypes, response] = useLazyQuery<TAllTypesResponse>(GET_ALL_TYPES)
  const [value, setValue] = useState<TValue>(defaultValue)

  useEffect(() => {
    if (autoFetch) {
      fetchAllTypes()
    }
  }, [autoFetch, fetchAllTypes])

  useEffect(() => {
    const result: TValue = defaultValue

    result.person.byCode = arrayToObject(
      response.data?.transactionalDb.allPersonTypes.nodes,
      'code',
    )
    result.person.byId = arrayToObject(
      response.data?.transactionalDb.allPersonTypes.nodes,
      'id',
    )

    result.access.byCode = arrayToObject(
      response.data?.transactionalDb.allPersonAccessTypes.nodes,
      'code',
    )
    result.access.byId = arrayToObject(
      response.data?.transactionalDb.allPersonAccessTypes.nodes,
      'id',
    )

    result.resident.byCode = arrayToObject(
      response.data?.transactionalDb.allResidentTypes.nodes,
      'code',
    )
    result.resident.byId = arrayToObject(
      response.data?.transactionalDb.allResidentTypes.nodes,
      'id',
    )

    result.lease.byCode = arrayToObject(
      response.data?.transactionalDb.allLeaseTypes.nodes,
      'code',
    )

    result.unit.byCode = arrayToObject(
      response.data?.transactionalDb.allUnitTypes.nodes,
      'code',
    )

    result.secret.byCode = arrayToObject(
      response.data?.transactionalDb.allSecretTypes.nodes,
      'code',
    )
    result.secret.byId = arrayToObject(
      response.data?.transactionalDb.allSecretTypes.nodes,
      'id',
    )

    result.serviceTicketCategory.byCode = arrayToObject(
      response.data?.transactionalDb.allServiceTicketCategoryTypes.nodes,
      'code',
    )
    result.serviceTicketCategory.byId = arrayToObject(
      response.data?.transactionalDb.allServiceTicketCategoryTypes.nodes,
      'id',
    )

    result.serviceTicketIssueLocation.byCode = arrayToObject(
      response.data?.transactionalDb.allServiceTicketIssueLocationTypes.nodes,
      'code',
    )
    result.serviceTicketIssueLocation.byId = arrayToObject(
      response.data?.transactionalDb.allServiceTicketIssueLocationTypes.nodes,
      'id',
    )

    result.device.byCode = arrayToObject(
      response.data?.transactionalDb.allDeviceTypes.nodes,
      'code',
    )
    result.device.byId = arrayToObject(
      response.data?.transactionalDb.allDeviceTypes.nodes,
      'id',
    )

    result.deviceLocation.byCode = arrayToObject(
      response.data?.transactionalDb.allDeviceLocationTypes.nodes,
      'code',
    )
    result.deviceLocation.byId = arrayToObject(
      response.data?.transactionalDb.allDeviceLocationTypes.nodes,
      'id',
    )

    result.deviceClass.byCode = arrayToObject(
      response.data?.transactionalDb.allDeviceClassTypes.nodes,
      'code',
    )
    result.deviceClass.byId = arrayToObject(
      response.data?.transactionalDb.allDeviceClassTypes.nodes,
      'id',
    )

    result.deviceManufacturer.byCode = arrayToObject(
      response.data?.transactionalDb.allDeviceManufacturerTypes.nodes,
      'code',
    )
    result.deviceManufacturer.byId = arrayToObject(
      response.data?.transactionalDb.allDeviceManufacturerTypes.nodes,
      'id',
    )

    if (updateCache) {
      LocalStorageUtils.setItem('personTypes', result.person)
      LocalStorageUtils.setItem('accessTypes', result.access)
      LocalStorageUtils.setItem('residentTypes', result.resident)
      LocalStorageUtils.setItem('leaseTypes', result.lease)
      LocalStorageUtils.setItem('unitTypes', result.unit)
      LocalStorageUtils.setItem('secretTypes', result.secret)

      LocalStorageUtils.setItem('deviceTypes', result.device)
      LocalStorageUtils.setItem('deviceClassTypes', result.deviceClass)
      LocalStorageUtils.setItem('deviceLocationTypes', result.deviceLocation)
      LocalStorageUtils.setItem('deviceManufacturerTypes', result.deviceManufacturer)
    }

    setValue(result)
  }, [response?.data?.transactionalDb])

  const getId = (type: keyof TValue, code: string) => {
    const id = value[type].byCode[code]?.id
    return id ? Number(id) : -1
  }

  const getCode = (type: keyof TValue, id: string | number) => {
    return value[type].byId[id]?.code || ''
  }

  const get = <K extends keyof TValue>(
    key: K,
    code: keyof TValue[K]['byCode'],
  ): TValue[K]['byCode'][typeof code] | undefined => {
    return value[key].byCode[code as any]
  }

  return {
    value,
    get,
    getId,
    getCode,
    response,
    fetch: fetchAllTypes,
  }
}

export default useAllTypes
