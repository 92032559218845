import {useQuery} from '@apollo/client'
import {
  TGetTenantSecretsResponse,
  TGetTenantSecretsVariables,
} from '../../data/graphql/queries/utility/types'
import {GET_TENANT_SECRETS} from '../../data/graphql/queries/utility'
import useDeviceManufacturerTypes from '../types/useDeviceManufacturerTypes'
import {
  DeviceManufacturerCodeEnum,
  TenantSecretTypeCodeEnum,
} from '../../data/graphql/queries/enums'
import {useCallback, useMemo} from 'react'

const useTenantSecrets = (tenantUuid?: string) => {
  const {DeviceManufacturerTypeIds} = useDeviceManufacturerTypes()

  const {refetch, ...query} = useQuery<
    TGetTenantSecretsResponse,
    TGetTenantSecretsVariables
  >(GET_TENANT_SECRETS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      condition: {
        isDeleted: false,
        isActive: true,
        tenantUuid,
        deviceManufacturerTypeId:
          DeviceManufacturerTypeIds[DeviceManufacturerCodeEnum.BRIVO],
      },
    },
  })

  const data = useMemo(() => {
    return query.data?.db.secrets.nodes?.filter(
      ({secretType}) => secretType.code === TenantSecretTypeCodeEnum.API_KEY,
    )
  }, [query.data?.db.secrets.nodes])

  const refetchWithTenant = useCallback(
    (tenantUuid: string) => {
      refetch({
        ...query.variables,
        condition: {
          ...query.variables?.condition,
          tenantUuid,
        },
      })
    },
    [refetch, query.variables],
  )

  return {
    query,
    data,
    refetch: refetchWithTenant,
    loading: query.loading,
  }
}

export default useTenantSecrets
