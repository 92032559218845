// components/Wizard/Wizard.tsx

import React, {FC} from 'react'
import './Wizard.style.scss'

interface Moment {
  label?: string
  status?: 'complete' | 'requires-action' | 'incomplete' | 'in-progress'
}

export interface WizardProps {
  id?: string
  timeline?: Moment[]
}

const defaultProps: WizardProps = {
  timeline: [],
}

const Wizard: FC<WizardProps> = (props: WizardProps) => {
  props = {...defaultProps, ...props}

  const isLast = i => props.timeline && i === props.timeline.length - 1

  return (
    <div id={props.id} className={'timeline'} data-testid={'Wizard'}>
      {props.timeline &&
        props.timeline.map((moment, i) => (
          <div
            key={i}
            className={`timeline-block ${i === 0 ? 'first' : ''} ${
              isLast(i) ? 'last' : ''
            }`}
          >
            <div className={`moment ${moment.status}`} data-label={moment.label}></div>
            {!isLast(i) && <div className={'line'}></div>}
          </div>
        ))}
    </div>
  )
}

export default Wizard
