import {useMemo} from 'react'
import LocalStorageUtils from '../../functions/localStorage.functions'
import {UnitTypeCodeEnum} from '../../data/graphql/queries/enums'

const useUnitTypes = () => {
  const UnitTypeIds = useMemo<Record<UnitTypeCodeEnum, number>>(() => {
    const types = LocalStorageUtils.getItem('unitTypes')

    const getId = (code: UnitTypeCodeEnum) => {
      return types?.byCode[code]?.id || -1
    }

    return {
      [UnitTypeCodeEnum.UNKNOWN]: getId(UnitTypeCodeEnum.UNKNOWN),
      [UnitTypeCodeEnum.APARTMENT]: getId(UnitTypeCodeEnum.APARTMENT),
      [UnitTypeCodeEnum.SHARED]: getId(UnitTypeCodeEnum.SHARED),
      [UnitTypeCodeEnum.MODEL]: getId(UnitTypeCodeEnum.MODEL),
    }
  }, [])

  return {UnitTypeIds}
}

export default useUnitTypes
