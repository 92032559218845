import {useContext, useMemo} from 'react'
import {AuthContext} from '../contexts/AuthContext'
import usePersonTypes from './types/usePersonTypes'
import {PersonTypeCodeEnum} from '../data/graphql/queries/enums'

const useCurrentProfile = () => {
  const {PersonTypeIds} = usePersonTypes()
  const {personProfile} = useContext(AuthContext)

  const isDevAdmin =
    Number(personProfile?.personTypeId) === PersonTypeIds[PersonTypeCodeEnum.DEV_ADMIN]

  const result = useMemo(() => {
    if (!personProfile) {
      return {
        id: 0,
        personTypeId: 0,
        residentTypeId: 0,
        tenantUuid: '',
        propertyId: 0,
        isDevAdmin,
      }
    }

    return {
      id: Number(personProfile.personProfileId),
      personTypeId: Number(personProfile.personTypeId),
      residentTypeId: Number(personProfile.residentTypeId),
      tenantUuid: personProfile.tenantUuid,
      propertyId: Number(personProfile.propertyId),
      isDevAdmin,
    }
  }, [personProfile, isDevAdmin])

  return result
}

export default useCurrentProfile
