import './PropertyDevices.style.scss'

import {useMemo} from 'react'
import {useQuery} from '@apollo/client'
import {GET_PROPERTY_DEVICES} from '../../data/graphql/queries/properties'
import {
  TPropertyDevicesRespone,
  TPropertyDevicesVariables,
} from '../../data/graphql/queries/properties/types'
import useToast from '../../hooks/useToast'
import {getInFilter} from '../../functions/filters'
import {arrayToObject} from '../../functions'
import useUserAccess from '../../hooks/useUserAccess'
import useDeviceClassTypes from '../../hooks/types/useDeviceClassTypes'
import {DeviceClassTypeCodeEnum} from '../../data/graphql/queries/enums'

export type TDeviceItem = {
  device: {
    code: string
    typeId: string
    description: string
    classTypeId: string
  }
  location: {
    code: string
    typeId: string
    description: string
  }
}

const usePropertyDevices = () => {
  const {showToast} = useToast()
  const {properties} = useUserAccess()
  const {DeviceClassTypeIds} = useDeviceClassTypes()

  const response = useQuery<TPropertyDevicesRespone, TPropertyDevicesVariables>(
    GET_PROPERTY_DEVICES,
    {
      skip: !properties?.length,
      variables: {
        filter: {
          deviceClassTypeId: {
            notIn: [
              DeviceClassTypeIds[DeviceClassTypeCodeEnum.BRIDGE],
              DeviceClassTypeIds[DeviceClassTypeCodeEnum.DOOR_SENSE],
              DeviceClassTypeIds[DeviceClassTypeCodeEnum.UNKNOWN],
            ],
          },
          propertyId: getInFilter(properties),
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Unable to Retrieve Floor Plans Data',
          type: 'error',
        })
      },
    },
  )

  const getDeviceClassTypeKey = (device?: Pick<TDeviceItem, 'device' | 'location'>) => {
    return `${device?.device.classTypeId}:${device?.location.code}`
  }

  const {unitReplacementDevices, unitClassTypeDevices, unitDevicesMap} = useMemo(() => {
    const result: {
      unitDevicesMap: Record<string, TDeviceItem[]>
      unitReplacementDevices: Record<
        string,
        {
          // [classTypeId:locationTypeId] of the device that will be replaced
          [classTypeKey: string]: TDeviceItem
        }
      >
      unitClassTypeDevices: Record<string, string[]>
    } = {
      unitDevicesMap: {},
      unitClassTypeDevices: {},
      unitReplacementDevices: {},
    }

    if (response.loading || !response.data) {
      return result
    }

    const {allDeviceInventoryViews, allDeviceTypes, allDeviceLocationTypes} =
      response.data.transactionalDb

    const unitDevicesMap: Record<string, Record<string, TDeviceItem>> = {}
    const unitReplacementDevices: Record<string, Record<string, TDeviceItem>> = {}
    const unitClassTypeDevices: Record<string, string[]> = {}

    const deviceTypesMap = arrayToObject(allDeviceTypes.nodes, 'typeId')
    const deviceLocationTypesMap = arrayToObject(allDeviceLocationTypes.nodes, 'typeId')

    allDeviceInventoryViews?.nodes.forEach(current => {
      const unitId = current.unitId
      const device = deviceTypesMap[current.deviceTypeId]
      const location = deviceLocationTypesMap[current.deviceLocationTypeId]
      const classTypeKey = getDeviceClassTypeKey({device, location})

      if (!unitDevicesMap[unitId]) {
        unitDevicesMap[unitId] = {}
      }

      if (!unitClassTypeDevices[unitId]) {
        unitClassTypeDevices[unitId] = []
      }

      if (!unitReplacementDevices[unitId]) {
        unitReplacementDevices[unitId] = {}
      }

      if (!unitDevicesMap[unitId][classTypeKey] || current.installedDeviceId) {
        unitDevicesMap[unitId][classTypeKey] = {
          device,
          location,
        }
      }

      if (!unitReplacementDevices[unitId][classTypeKey] || !current.installedDeviceId) {
        unitReplacementDevices[unitId][classTypeKey] = {
          device,
          location,
        }
      }

      unitClassTypeDevices[unitId].push(classTypeKey)
    }, {})

    result.unitClassTypeDevices = unitClassTypeDevices
    result.unitReplacementDevices = unitReplacementDevices
    result.unitDevicesMap = Object.keys(unitDevicesMap).reduce<
      Record<string, TDeviceItem[]>
    >((result, unitId) => {
      result[unitId] = Object.values(unitDevicesMap[unitId])

      return result
    }, {})

    return result
  }, [response.loading, response.data])

  return {
    unitReplacementDevices,
    unitClassTypeDevices,
    unitDevicesMap,
    loading: response.loading,
  } as const
}

export default usePropertyDevices
