import {useCallback} from 'react'
import {TAccessPointsValue} from '../useAccessPoints'

import useInstallerAccessPoints from './useInstallerAccessPoints'
import InputToggle from '../../../components/InputToggle'
import {TDeviceItem, TDevices, TStructure} from './InstallerAccessPoints'
import useUnavailableDevices from '../../../hooks/data/useUnavailableDevices'
import {getInventoryId} from '../../../functions/lock.functions'
import useDeviceClassTypes from '../../../hooks/types/useDeviceClassTypes'
import {DeviceClassTypeCodeEnum} from '../../../data/graphql/queries/enums'

export type Props = {
  vendorUserId?: number
  value: TAccessPointsValue<TDevices>
  installerData: ReturnType<typeof useInstallerAccessPoints>
}

const useInstallerUnitRow = ({vendorUserId, value, installerData}: Props) => {
  const {building, property, toggleUnit, openDevicesModal} = installerData

  const {DeviceClassTypeIds} = useDeviceClassTypes()
  const {devicesByInventoryId} = useUnavailableDevices(vendorUserId)
  const propertyAccesses = property ? value[property?.propertyId] : null
  const buildingAccesses = building
    ? propertyAccesses?.buildings?.[building?.buildingId]
    : null

  const getDevicesForLabel = useCallback(
    (sourceDevices?: TDevices | null, structure?: TStructure) => {
      if (!sourceDevices || !structure) {
        return []
      }

      const devices = installerData.getStructureDevices(structure, sourceDevices)

      const getDeviceLabel = ({device, location}: TDeviceItem) => {
        if (
          Number(device.classTypeId) === DeviceClassTypeIds[DeviceClassTypeCodeEnum.LOCK]
        ) {
          return location.description + ' Lock'
        }

        return location.description + ' Thermostat'
      }

      const filterOutDevice = (item: TDeviceItem) => {
        if (!structure.unitId) {
          return !!item.checked
        }

        const inventoryId = getInventoryId(
          structure.unitId,
          item.device.typeId,
          item.location.typeId,
        )

        const device = devicesByInventoryId[inventoryId]
        const isChecked = !!item.checked
        const isInstalled = !!device?.installedDeviceId
        const isAlreadyAssigned = !!device?.assignedVendorId

        return isChecked && !isInstalled && !isAlreadyAssigned
      }

      const labels = devices.filter(filterOutDevice)

      return [...labels].map(item => ({
        ...item,
        label: getDeviceLabel(item),
      }))
    },
    [vendorUserId, installerData.getStructureDevices, devicesByInventoryId],
  )

  const getDevicesLabel = useCallback(
    (sourceDevices?: TDevices | null, structure?: TStructure) => {
      const labels = getDevicesForLabel(sourceDevices, structure).map(({label}) => label)

      if (!labels.length) {
        return 'No available devices'
      }

      const ending = labels.length > 2 ? ' ...' : ''
      return labels.splice(0, 2).join(', ') + ending
    },
    [getDevicesForLabel],
  )

  const isUnitSelected = useCallback(
    (unitId: string) => {
      if (buildingAccesses) {
        return !!Object.hasOwn(buildingAccesses?.units || {}, unitId)
      }

      return false
    },
    [buildingAccesses],
  )

  const isToggleDisabled = useCallback(
    (unitId: string): boolean => {
      const unitDevices = buildingAccesses?.units?.[unitId]

      if (!unitDevices) {
        return false
      }

      if (!unitDevices.length) {
        return false
      }

      const availableDeviceExists = unitDevices.some(device => !device.disabled)

      return !availableDeviceExists
    },
    [buildingAccesses],
  )

  const renderActiveSchedule = useCallback(
    (unitId: string) => {
      if (!isUnitSelected(unitId)) {
        return null
      }

      const unitDevices = buildingAccesses?.units?.[unitId]
      const sourceDevices =
        unitDevices || buildingAccesses?.data || propertyAccesses?.data

      const openUnitSchedule = () => {
        openDevicesModal({unitId})
      }

      return (
        <span className='schedule-link' onClick={openUnitSchedule}>
          {getDevicesLabel(sourceDevices, {unitId})}
        </span>
      )
    },
    [isUnitSelected, getDevicesForLabel, openDevicesModal],
  )

  const renderActionCell = useCallback(
    (unitId: string) => {
      return (
        <InputToggle
          disabled={isToggleDisabled(unitId)}
          isChecked={isUnitSelected(unitId)}
          onValueChange={value => {
            toggleUnit(value, unitId)
          }}
        />
      )
    },
    [toggleUnit, isUnitSelected, isToggleDisabled],
  )

  const renderUnitRow = useCallback(
    (unitId: string) => ({
      devices: renderActiveSchedule(unitId),
      isEnabled: renderActionCell(unitId),
    }),
    [renderActionCell, renderActiveSchedule],
  )

  return {render: renderUnitRow, getDevicesLabel}
}

export default useInstallerUnitRow
