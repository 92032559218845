import {useSigninCheck} from 'reactfire'
import useAllTypes from './data/useAllTypes'
import {useEffect, useState} from 'react'

const useAuthGuard = () => {
  const signInCheck = useSigninCheck()
  const {fetch: fetchTypes} = useAllTypes({autoFetch: false, updateCache: true})

  const [isLoading, setLoading] = useState(signInCheck.status === 'loading')
  const [isSignedIn, setSignedIn] = useState(signInCheck.data?.signedIn)

  useEffect(() => {
    setLoading(true)

    if (!signInCheck.data?.signedIn || signInCheck.data?.user?.isAnonymous) {
      setLoading(signInCheck.status === 'loading')
      setSignedIn(false)

      return
    }

    fetchTypes().finally(() => {
      setLoading(false)
      setSignedIn(true)
    })
  }, [
    fetchTypes,
    signInCheck.status,
    signInCheck.data?.signedIn,
    signInCheck.data?.user?.isAnonymous,
  ])

  return {
    isAnonymous: !!signInCheck.data?.user?.isAnonymous,
    hasEmail: !!signInCheck.data?.user?.email,
    isSignedIn: isSignedIn,
    isLoading: isLoading,
  }
}

export default useAuthGuard
