import {useEffect, useState} from 'react'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import useAllTypes from '../data/useAllTypes'
import ObjectUtils from '../../functions/object.functions'

const useServiceTicketCategoryFilter = (initialValue?: number[]) => {
  const allTypes = useAllTypes()

  const [categories, setCategories] = useState<CheckboxItem[]>([])

  useEffect(() => {
    const categories = ObjectUtils.values(allTypes.value.serviceTicketCategory.byId)

    setCategories(
      categories.map(item => ({
        id: Number(item?.id),
        name: item?.description || '',
        checked: !!initialValue?.includes(Number(item?.id)),
      })),
    )
  }, [allTypes.value.serviceTicketCategory.byId])

  return [categories, setCategories] as const
}

export default useServiceTicketCategoryFilter
