import './ImportFailures.style.scss'

import {useCallback, useContext, useEffect, useMemo} from 'react'
import DataGrid from '../../../components/DataGrid'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import Paginator from '../../../components/Paginator'
import Search from '../../../layouts/People/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import Panel from '../../../components/Panel/Panel'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'
import useImportFailures from '../../../hooks/data/useImportFailuresData'
import ImportFailuresFilter, {TImportFailuresFilterFields} from './ImportFailuresFilter'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'

type TGuestRow = {
  id: string
  name: JSX.Element | string
  unit: string
  property: string
  email: JSX.Element | string
  phone: JSX.Element | string
  isEmailDuped: JSX.Element | string
  isPhoneDuped: JSX.Element | string
}

const PAGE_SIZE = 10

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'name:asc', value: 'PERSON_NAME_ASC', label: 'Name Asc'},
  {sortKey: 'name:desc', value: 'PERSON_NAME_DESC', label: 'Name Desc'},
  {sortKey: 'email:asc', value: 'EMAIL_ASC', label: 'Email Asc'},
  {sortKey: 'email:desc', value: 'EMAIL_DESC', label: 'Email Desc'},
  {sortKey: 'phone:asc', value: 'MOBILE_PHONE_ASC', label: 'Mobile Phone Asc'},
  {sortKey: 'phone:desc', value: 'MOBILE_PHONE_DESC', label: 'Mobile Phone Desc'},
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Number Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Number Desc'},

  {
    sortKey: 'isPhoneDuped:asc',
    value: 'MOBILE_PHONE_IS_DUPED_ASC',
    label: 'Mobile Phone Is Duped Asc',
  },
  {
    sortKey: 'isPhoneDuped:desc',
    value: 'MOBILE_PHONE_IS_DUPED_DESC',
    label: 'Mobile Phone Is Duped Desc',
  },
  {sortKey: 'isEmailDuped:asc', value: 'EMAIL_IS_DUPED_ASC', label: 'Email Is Duped Asc'},
  {
    sortKey: 'isEmailDuped:desc',
    value: 'EMAIL_IS_DUPED_DESC',
    label: 'Email Is Duped Desc',
  },
]

const ImportFailures = () => {
  const {setQuery} = useContext(ExportTableContext)

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    setQueryOptions,
    onChangeNumberOfItems,
  } = useQueryOptions<TImportFailuresFilterFields>({
    limit: PAGE_SIZE,
    page: 1,
    orderBy: [],
    searchTerm: '',
    filters: {},
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    people,
    response: importResponse,
    variables: queryVariables,
    queryForDownloadTable,
  } = useImportFailures(debouncedSearchTerm, queryOptions)

  const onChangeSortOrder = (value: string) => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  const onSubmitFilter = useCallback(
    (filters: TImportFailuresFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const guestsRows = useMemo<TGuestRow[]>(() => {
    if (!people) {
      return []
    }

    return people.map(person => ({
      id: person.id,
      name: <CellWithAvatar name={person.personName} />,
      unit: person.unitNumber || '—',
      property: person.propertyName || '—',
      email: person.email ? (
        person.email
      ) : (
        <span className='missing-import-field'>Missing email</span>
      ),
      phone: person.mobilePhone ? (
        person.mobilePhone
      ) : (
        <span className='missing-import-field'>Missing mobile phone</span>
      ),
      isEmailDuped: person.emailIsDuped ? (
        <span className='missing-import-field'>Duped email</span>
      ) : (
        'No'
      ),
      isPhoneDuped: person.mobilePhoneIsDuped ? (
        <span className='missing-import-field'>Duped phone number</span>
      ) : (
        'No'
      ),
    }))
  }, [people])

  const onChangeSearchQuery = useCallback(
    (query: string) => {
      upsertQueryOptions(prev => ({
        ...prev,
        searchTerm: query,
        page: 1,
      }))
    },
    [upsertQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [setQueryOptions],
  )

  const dataForTableQuery = useCallback(async () => {
    try {
      const {data} = await queryForDownloadTable()

      const logs = data?.transactionalDb.allPortalPersonViews.nodes

      const tableData = logs.map(resident => {
        const row: Omit<TGuestRow, 'id'> = {
          name: resident.personName || '—',
          property: resident.property || '—',
          unit: resident.unitNumber || '—',
          email: '',
          phone: '',
          isEmailDuped: '',
          isPhoneDuped: '',
        }

        return Object.values(row)
      })

      tableData.unshift([
        'Name',
        'Property',
        'Unit',
        'Email',
        'Phone',
        'Is email duped',
        'is phone duped',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const emptyGuestsTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Table is empty...',
  })

  const itemsCount = importResponse.data?.db.ingestIssues.totalCount || 0

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'ImportFailures'} data-testid={'ImportFailuresView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search residents'
                    value={queryOptions.searchTerm}
                    onValueChange={onChangeSearchQuery}
                  />
                  <SearchFilters
                    filter={ImportFailuresFilter}
                    initialValue={queryOptions.filters}
                    onSubmit={onSubmitFilter}
                  />

                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>

                <Panel theme={'white'}>
                  <DataGrid
                    loading={importResponse.loading}
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    order={tableSort.order}
                    columns={[
                      {name: 'Name', key: 'name', sortable: true},
                      {name: 'Email', key: 'email', sortable: true},
                      {name: 'Mobile Phone', key: 'phone', sortable: true},
                      {
                        name: 'Is Mobile Phone Duped',
                        key: 'isPhoneDuped',
                        sortable: true,
                      },
                      {name: 'Is Email Duped', key: 'isEmailDuped', sortable: true},
                      {name: 'Unit Number', key: 'unit', sortable: true},
                      {name: 'Property Name', key: 'property'},
                    ]}
                    selectableRows
                    emptyTableComponent={emptyGuestsTable}
                    rows={guestsRows}
                  />
                </Panel>

                <TableFooter itemCount={itemsCount} loading={importResponse.loading}>
                  <Paginator
                    itemCount={itemsCount}
                    perPage={queryOptions.limit}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default ImportFailures
