import './Card.style.scss'

import React, {ForwardRefRenderFunction} from 'react'
import classNames from 'classnames'

export type CardProps = {
  id?: string
  theme?: 'default' | 'gray' | 'semi-dark' | 'dark'
  padding?: 'none' | 'xs' | 'sm' | 'md' | 'lg'
  image?: string
  noShadow?: boolean
  noMargin?: boolean
  imageWidth?: string
  imageHeight?: string
  imagePosition?: 'top' | 'side'
  children?: React.ReactNode
  className?: string
  onClick?: () => void
}

const defaultProps: CardProps = {
  theme: 'default',
  padding: 'sm',
  imageHeight: '100px',
  imageWidth: '20%',
  imagePosition: 'top',
}

const Card: ForwardRefRenderFunction<HTMLDivElement, CardProps> = (
  {className = '', ...props}: CardProps,
  forwardedRef,
) => {
  props = {...defaultProps, ...props}

  const widthStyle = props.imagePosition === 'side' ? props.imageWidth : ''
  const heightStyle = props.imagePosition === 'top' ? props.imageHeight : ''

  return (
    <div
      id={props.id}
      ref={forwardedRef}
      className={classNames(
        'card',
        props.theme,
        {
          'no-shadow': props.noShadow,
          'no-margin': props.noMargin,
          'image-top': props.imagePosition === 'top',
          'image-side': props.imagePosition === 'side',
          [`padding-${props.padding}`]: props.padding !== 'none',
        },
        className,
      )}
      data-testid={'Card'}
      onClick={props.onClick}
    >
      {props.image && (
        <div
          className={`card-image`}
          style={{
            backgroundImage: `url(${props.image})`,
            minHeight: `${heightStyle}`,
            minWidth: `${widthStyle}`,
          }}
        ></div>
      )}
      <div className={'card-content'}>{props.children}</div>
    </div>
  )
}

export default React.memo(React.forwardRef(Card))
