import {useCallback, useContext, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Badge from '../../../components/Badge'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import {capitalize, toCommonDateFormat} from '../../../functions'
import useGuestsData from '../../../hooks/data/useGuestsData'
import {BadgeProps} from '../../../components/Badge/Badge'

import GuestsFilters from '../../People/Guests/GuestsFilter'
import People from './People'
import {handlePersonAccessCodeGuests} from '../../../functions/guests.functions'
import {AccessScheduleTypes} from '../../../data/graphql/mutations/lock/types'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'

type TGuestRow = {
  id: number
  name: JSX.Element | string
  access: React.ReactNode
  assignedResident: string
  accessType: string
  property: string
  unit: string
  building: string
  unitId: number
  activeDate: string
  deactiveDate: string
  status: JSX.Element
  personAccessId: number
}

const PAGE_SIZE = 10

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'name:asc', value: 'PERSON_NAME_ASC', label: 'Name Asc'},
  {sortKey: 'name:desc', value: 'PERSON_NAME_DESC', label: 'Name Desc'},
  {sortKey: 'access:asc', value: 'LOCK_SCHEDULE_TYPE_ASC', label: 'Schedule Type Asc'},
  {sortKey: 'access:desc', value: 'LOCK_SCHEDULE_TYPE_DESC', label: 'Schedule Type Desc'},
  {
    sortKey: 'assignedResident:asc',
    value: 'INVITED_BY_PERSON_NAME_ASC',
    label: 'Invited By Asc',
  },
  {
    sortKey: 'assignedResident:desc',
    value: 'INVITED_BY_PERSON_NAME_DESC',
    label: 'Invited By Desc',
  },
  {sortKey: 'property:asc', value: 'PROPERTY_NAME_ASC', label: 'Property Asc'},
  {sortKey: 'property:desc', value: 'PROPERTY_NAME_DESC', label: 'Property Desc'},
  {sortKey: 'property:asc', value: 'PROPERTY_NAME_ASC', label: 'Property Asc'},
  {sortKey: 'property:desc', value: 'PROPERTY_NAME_DESC', label: 'Property Desc'},
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Desc'},
  {sortKey: 'building:desc', value: 'BUILDING_NAME_ASC', label: 'Building Name Desc'},
  {sortKey: 'building:desc', value: 'BUILDING_NAME_DESC', label: 'Building Name Desc'},
  {
    sortKey: 'activeDate:asc',
    value: 'LOCK_SCHEDULE_START_DT_ASC',
    label: 'Active Date Asc',
  },
  {
    sortKey: 'activeDate:desc',
    value: 'LOCK_SCHEDULE_START_DT_DESC',
    label: 'Active Date Desc',
  },
  {
    sortKey: 'deactiveDate:asc',
    value: 'LOCK_SCHEDULE_END_DT_ASC',
    label: 'Deactive Date Asc',
  },
  {
    sortKey: 'deactiveDate:desc',
    value: 'LOCK_SCHEDULE_END_DT_DESC',
    label: 'Deactive Date Desc',
  },
]

const Guests = () => {
  const {setQuery} = useContext(ExportTableContext)
  const navigate = useNavigate()

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    setQueryOptions,
    onChangeNumberOfItems,
  } = useQueryOptions({
    limit: PAGE_SIZE,
    page: 1,
    orderBy: ['PERSON_NAME_ASC'],
    searchTerm: '',
    filters: {},
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    guests: guestsList,
    response: guestsResponse,
    variables: queryVariables,
    queryForDownloadTable,
  } = useGuestsData(debouncedSearchTerm, queryOptions, true)
  const [guestsRows, setGuests] = useState<TGuestRow[]>([])

  const onChangeSortOrder = (value: string) => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  useEffect(() => {
    if (guestsList) {
      const newGuestsRows: TGuestRow[] = []

      guestsList.forEach(personAccess => {
        newGuestsRows.push({
          id: personAccess.personAccessId,
          personAccessId: personAccess.personAccessId,
          name: <CellWithAvatar name={personAccess.personName} />,
          status: (
            <Badge
              theme={personAccess.isPersonAccessActive ? 'info' : 'danger'}
              size={'sm'}
            >
              {personAccess.isPersonAccessActive ? 'Active' : 'Inactive'}
            </Badge>
          ),
          access:
            renderAccessBadge(
              personAccess.lockScheduleType,
              personAccess.lockScheduleStartDt,
              personAccess.lockScheduleEndDt,
            ) || '—',
          assignedResident: personAccess.invitedByPersonName || '—',
          accessType: handlePersonAccessCodeGuests(personAccess.personAccessCode) || '-',
          property: personAccess.propertyName,
          unit: personAccess.unitNumber,
          building: personAccess.buildingName,
          unitId: personAccess.unitId,
          activeDate: toCommonDateFormat(personAccess.lockScheduleStartDt) || '—',
          deactiveDate: toCommonDateFormat(personAccess.lockScheduleEndDt) || '—',
        })
      })

      setGuests(newGuestsRows)
    } else {
      setGuests([])
    }
  }, [guestsList])

  const renderAccessBadge = (
    accessType?: AccessScheduleTypes,
    startDate = '',
    endDate = '',
    forTable?,
  ) => {
    const now = Date.now()
    let theme: BadgeProps['theme'] = 'info'

    if (Date.parse(startDate) > now || Date.parse(endDate) < now) {
      theme = 'default'
    }

    return accessType ? (
      forTable ? (
        capitalize(accessType)
      ) : (
        <Badge theme={theme} size={'sm'}>
          {capitalize(accessType)}
        </Badge>
      )
    ) : null
  }

  const onChangeSearchQuery = useCallback(
    (query: string) => {
      upsertQueryOptions(prev => ({
        ...prev,
        searchTerm: query,
        page: 1,
      }))
    },
    [upsertQueryOptions],
  )

  const onPressRow = useCallback(
    (index: number) => {
      navigate(`/reports/people/guests/${guestsRows[index].personAccessId}`)
    },
    [navigate, guestsRows],
  )

  const onSubmitFilter = useCallback(
    filters => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataForTableQuery = useCallback(async () => {
    try {
      const {data} = await queryForDownloadTable()

      const logs = data?.transactionalDb.allPortalPersonViews.nodes

      const tableData = logs.map(resident => {
        return Object.values({
          name: resident.personName || '—',
          status: resident.isPersonAccessActive ? 'Active' : 'Inactive',
          access:
            renderAccessBadge(
              resident.lockScheduleType,
              resident.lockScheduleStartDt,
              resident.lockScheduleEndDt,
              true,
            ) || '—',
          assignedResident: resident.invitedByPersonName || '—',
          accessType: handlePersonAccessCodeGuests(resident.personAccessCode) || '-',
          property: resident.propertyName || '—',
          building: resident.buildingName || '—',
          unit: resident.unitNumber || '—',
          activeDate: toCommonDateFormat(resident.lockScheduleStartDt) || '—',
          deactiveDate: toCommonDateFormat(resident.lockScheduleEndDt) || '—',
        })
      })

      tableData.unshift([
        'Name',
        'Status',
        'Access',
        'Assigned Resident',
        'Access Type',
        'Property',
        'Building',
        'Unit',
        'Active Date',
        'Deactive Date',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const emptyGuestsTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Guests table is empty...',
  })

  const itemCount =
    guestsResponse.data?.transactionalDb?.allPortalPersonViews?.totalCount || 0

  return (
    <People
      onChangeNumberOfItems={onChangeNumberOfItems}
      upsertQueryOptions={upsertQueryOptions}
      onTypeSearchField={onChangeSearchQuery}
      onPressRow={onPressRow}
      Filters={GuestsFilters}
      queryOptions={queryOptions}
      tableProps={{
        order: tableSort.order,
        selectedColumn: tableSort.column,
        selectedColumnChange: tableSort.setSortColumn,
        rows: guestsRows,
        columns: [
          {name: 'Name', key: 'name', sortable: true},
          {name: 'Status', key: 'status'},
          {name: 'Access', key: 'access', sortable: true},
          {
            name: 'Assigned Resident',
            key: 'assignedResident',
            sortable: true,
          },
          {name: 'Access Type', key: 'accessType'},
          {name: 'Property', key: 'property', sortable: true},
          {name: 'Building', key: 'building', sortable: true},
          {name: 'Unit', key: 'unit', sortable: true},
          {name: 'Active Date', key: 'activeDate', sortable: true},
          {name: 'Deactive Date', key: 'deactiveDate', sortable: true},
        ],
      }}
      count={itemCount}
      loading={guestsResponse.loading}
      className={'Guests'}
      testId={'GuestsReports'}
      searchPlaceholder={'Search guests'}
      onChangeSortOrder={onChangeSortOrder}
      onSubmitFilter={onSubmitFilter}
      sortOptions={sortOptions}
      emptyTableComponent={emptyGuestsTable}
    />
  )
}

export default Guests
