import {useMemo} from 'react'
import LocalStorageUtils from '../../functions/localStorage.functions'
import {AccessTypeCodeEnum} from '../../data/graphql/queries/enums'

const usePersonAccessTypes = () => {
  const PersonAccessTypeIds = useMemo<Record<AccessTypeCodeEnum, number>>(() => {
    const types = LocalStorageUtils.getItem('accessTypes')

    const getId = (code: AccessTypeCodeEnum) => {
      return types?.byCode[code]?.id || -1
    }

    return {
      [AccessTypeCodeEnum.UNKNOWN]: getId(AccessTypeCodeEnum.UNKNOWN),
      [AccessTypeCodeEnum.OWNER]: getId(AccessTypeCodeEnum.OWNER),
      [AccessTypeCodeEnum.RESIDENT]: getId(AccessTypeCodeEnum.RESIDENT),
      [AccessTypeCodeEnum.GUEST]: getId(AccessTypeCodeEnum.GUEST),
      [AccessTypeCodeEnum.PIN_CODE]: getId(AccessTypeCodeEnum.PIN_CODE),
      [AccessTypeCodeEnum.PROPERTY_ACCOUNT]: getId(AccessTypeCodeEnum.PROPERTY_ACCOUNT),
      [AccessTypeCodeEnum.BUILDING_ACCOUNT]: getId(AccessTypeCodeEnum.BUILDING_ACCOUNT),
    }
  }, [])

  return {PersonAccessTypeIds}
}

export default usePersonAccessTypes
