import {useMemo} from 'react'
import LocalStorageUtils from '../../functions/localStorage.functions'
import {DeviceManufacturerCodeEnum} from '../../data/graphql/queries/enums'

const useDeviceManufacturerTypes = () => {
  const DeviceManufacturerTypeIds = useMemo<
    Record<DeviceManufacturerCodeEnum, number>
  >(() => {
    const types = LocalStorageUtils.getItem('deviceManufacturerTypes')

    const getId = (code: DeviceManufacturerCodeEnum) => {
      return types?.byCode[code]?.id || -1
    }

    return {
      [DeviceManufacturerCodeEnum.UNKNOWN]: getId(DeviceManufacturerCodeEnum.UNKNOWN),
      [DeviceManufacturerCodeEnum.YALE]: getId(DeviceManufacturerCodeEnum.YALE),
      [DeviceManufacturerCodeEnum.HONEYWELL]: getId(DeviceManufacturerCodeEnum.HONEYWELL),
      [DeviceManufacturerCodeEnum.NEST]: getId(DeviceManufacturerCodeEnum.NEST),
      [DeviceManufacturerCodeEnum.BRIVO]: getId(DeviceManufacturerCodeEnum.BRIVO),
    }
  }, [])

  return {DeviceManufacturerTypeIds}
}

export default useDeviceManufacturerTypes
