import {TProperty as TPropertyEntity, TBuilding, TUnitView} from './../entities'
import {TBuildingWithUnits, TQueryFilter} from '../common/types'
import {TLease, TLeaseType} from '../entities'
import {DepartmentCodeEnum} from '../enums'

export enum ServiceTicketCategories {
  APPLIANCE = 1,
  EXTERIOR = 2,
  DOORS_AND_LOCKS = 3,
  ELECTRICAL = 4,
  FLOORING = 5,
  GENERAL = 6,
  GROUNDS = 7,
  HEATING_AND_COOLING = 8,
  INSPECTION = 9,
  PLUMBING = 10,
  RECREATIONAL = 11,
  SAFETY = 12,
  COMMUNICATIONS = 13,
  PREVENTATIVE_MAINTENANCE = 14,
  SMART_HOME = 15,
}

export enum ServiceTicketStatuses {
  UNKNOWN = 0,
  CANCELLED = 1,
  COMPLETED = 2,
  IN_PROGRESS = 3,
  ON_HOLD = 4,
}

export type TPropertyPreview = {
  propertyId: number
  propertyName: string
  addressByAddressId: {
    address1: string
    address2: string
    city: string
    stateId: string
    zipCode: string
    stateByStateId: {
      code: string
    }
  }
  residents: {
    totalCount: number
  }
  guests: {
    totalCount: number
  }
  staff: {
    nodes: {
      personByPersonId?: {
        firstName: string
        lastName: string
      }
    }[]
  }
}

export type TPropertiesListResponse = {
  transactionalDb: {
    allProperties: {
      nodes: TPropertyPreview[]
    }
  }
}

export type TPropertiesListVariables = {
  first?: number
  offset?: number

  filter?: TQueryFilter<{
    propertyId: number
  }>
  staffCondition?: {
    isActive: boolean
    departmentCode: DepartmentCodeEnum
  }
  guestsFilter?: TQueryFilter<{
    personAccessTypeId: number
    unitId: number
    miscInfo: object
    isActive: boolean
    isDeleted: boolean
  }>
  residentsFilter?: TQueryFilter<{
    personAccessTypeId: number
    unitId: number
    miscInfo: object
    isActive: boolean
    isDeleted: boolean
  }>
}

export type TAvailablePropertiesResponse = {
  db: {
    properties: {
      totalCount: number
      nodes: {
        id: TProperty['propertyId']
        name: TProperty['propertyName']
      }[]
    }
  }
}

export type TAvailablePropertiesVariables = {
  filter?: TQueryFilter<{
    isDeleted: boolean
    isActive: boolean
    propertyId: number
  }>
}

export type TProperty = {
  propertyId: number
  propertyName: string
  addressByAddressId: {
    address1: string
    address2: string
    city: string
    stateId: string
    zipCode: string
    stateByStateId: {
      code: string
    }
  }
}

export type TPropertyResponse = {
  transactionalDb: {
    propertyByPropertyId: TProperty | null
  }
}

export type TPropertyVariables = {
  propertyId: number
}

export type UnitRecentActivity = {
  action: any
  reason: any
  state: any
  status: any
  callingUserId: any
  transactionId: any
  step: any
  message: any
  createdId: string
  warningLevel: any
  createdDt: string
  phone: string
  lastName: string
  firstName: string
  lockId: string
  email: string
  userId: string
  eventId: string
  eventType: string
  event: string
  device: string
  personId: string
  timeStamp: string
  unitId: string
  tenantUuid: string
  lockActivityLogId: string
  nodeId: string
  otherUserId: any
  pinSecret: string
  syncType: any
  error: any
  errorName: any
  originalPayload: any
}

export type TAllAccessPointsVariables = {
  filter?: TQueryFilter<{
    propertyId?: number | null
    isActive?: boolean | null
    isDeleted?: boolean | null
  }>
  buildingsFilter?: TQueryFilter<{
    buildingId?: number
    isActive: boolean
    isDeleted: boolean
  }>
}

export type TAllAccessPointsResponse = {
  transactionalDb: {
    allProperties: {
      nodes: {
        propertyId: string
        propertyName: string
        buildingsByPropertyId: {
          nodes: TBuildingWithUnits[]
        }
      }[]
    }
  }
}

export type TLeaseOverviewVariables = {
  overdueLeasesFilter: TQueryFilter<{
    isActive: boolean
    currentBalance: number
    unitId?: number
  }>
  leasedUnitsCondition: {
    isActive: boolean
    isExpired: boolean
  }
  unitsCondition: {
    isActive: boolean
    isDeleted: boolean
    unitTypeId: number
    propertyId?: number
  }
  unitsFilter: TQueryFilter<{
    buildingId?: number
  }>
  leasedUnitsFilter: TQueryFilter<{
    unitId?: number
    moveOutDate?: string
    leaseTypeId?: number
  }>
}

export type TLeaseOverviewResponse = {
  transactionalDb: {
    allUnits: {
      totalCount: number
    }
    leasedUnits: {
      totalCount: number
      nodes: {
        unitId: string
      }[]
    }
    overdueLeases: {
      nodes: {
        currentBalance: string
      }[]
    }
  }
}

export type TCommonAreaVariables = {
  propertyId?: string
  unitId?: string
}

export type TCommonAreaResponse = {
  transactionalDb: {
    propertyByPropertyId: {
      propertyName: string
    }
    unitByUnitId: {
      unitNumber: string
    }
  }
}

export type TFloorPlansVariables = {
  condition: {
    isActive: boolean
    isDeleted: boolean
    propertyId?: number
  }
  orderBy?: string[]
}

export type TFloorPlansResponse = {
  transactionalDb: {
    allFloorPlans: {
      nodes: {
        floorPlanId: string
        name: string
        description: string
      }[]
    }
  }
}

export type TFloorPlansDevicesVariables = {
  filter?: TQueryFilter<{
    propertyId?: number
  }>
}

export type TFloorPlansDevicesResponse = {
  transactionalDb: {
    allFloorPlans: {
      nodes: {
        floorPlanId: string
        unitsByFloorPlanId: {
          nodes: {
            unitId: string
          }[]
        } | null
        devices: {
          nodes: {
            device: {
              code: string
              typeId: string
              description: string
              classTypeId: string
            }
            location: {
              code: string
              typeId: string
              description: string
            }
          }[]
        }
      }[]
    }
  }
}

export type TServiceTicketsVariables = {
  first?: number
  offset?: number
  condition?: {
    isActive?: boolean
    isDeleted?: boolean
    propertyId?: number
  }
  filter?: TQueryFilter<{
    unitId?: number
    unitNumber?: string
    propertyId?: number
    buildingId?: number
    serviceTicketStatusTypeId?: number
    serviceTicketCategoryTypeId?: number
    serviceTicketIssueLocationTypeId?: number
    modifiedDt?: string
    technicianName?: string
  }>
  orderBy?: string[]
}

export type TServiceTicketsResponse = {
  transactionalDb: {
    serviceTickets: {
      totalCount: number
      nodes: {
        technicianName: string | null
        unitNumber: string
        serviceComments: string | null
        modifiedDt: string

        categoryTypeId: string
        locationTypeId: string
        issueTypeId: string
      }[]
    }
  }
}

export type TServiceTicketsInfoResponse = {
  transactionalDb: {
    categoryTypes: {
      nodes: {
        id: string
        description: string
      }[]
    }
    issueLocationTypes: {
      nodes: {
        id: string
        description: string
      }[]
    }
    issueTypes: {
      nodes: {
        id: string
        description: string
      }[]
    }
  }
}

export type TUnitDetailsVariables = {
  unitId: number
  propertyId: number

  leaseCondition: {
    isActive: boolean
    isDeleted: boolean
    isExpired: boolean
  }

  locksFilter: TQueryFilter<{
    isActive: boolean
    isDeleted: boolean
    deviceTypeId: number
  }>

  thermostatsFilter: TQueryFilter<{
    isActive: boolean
    isDeleted: boolean
    deviceTypeId: number
  }>

  residentsCondition: {
    unitId: number
    isActive: boolean
    isDeleted: boolean
  }
  personAccessesCondition: {
    isActive: boolean
    isDeleted: boolean
    personAccessTypeId: number
    unitId: number
  }

  guestsFilter: TQueryFilter<{
    unitId: number
    personType: string
    personAccessCode: string
    isPersonAccessActive: boolean
  }>
}

export type TUnitDetailsRespone = {
  db: {
    unit: {
      locks: {
        totalCount: number
      }
      thermostats: {
        totalCount: number
      }

      unitNumber: string
      building: {
        buildingName: string
        buildingNumber: string
        addressId: string
      }

      unitType: {
        description: string
      }

      stageCompKey: string
      leases: {
        nodes: {
          leaseId: string
          leaseholder: {
            email: string
            lastName: string
            firstName: string
            mobilePhone: string
          }
        }[]
      }
    }

    property: {
      propertyName: string
      address: {
        address1: string
        address2: string
        city: string
        zipCode: string
        state: {
          description: string
          code: string
        } | null
      } | null
    }

    residents: {
      nodes: {
        lease?: {
          leaseId: string
          isActive: TLease['isActive']
          isDeleted: TLease['isDeleted']
          isExpired: TLease['isExpired']
          moveOutDate: TLease['moveOutDate']
          type: {
            code: TLeaseType['code']
          }
        }

        person: {
          personId: string
          firstName: string
          lastName: string

          accesses: {
            nodes: {
              personProfileId: string
              personAccessId: string
            }[]
          }
        }
      }[]
    }

    guests: {
      nodes: {
        personId: string
        personProfileId: string
        personName: string
        unitId: string
        personAccessId: string
      }[]
    }
  }
}

export type TCommonAreasByGroupVariables = {
  input: {
    propertyId: TProperty['propertyId']
    deviceMaker: string
  }
}

export type TCommonAreasByGroupRespone = {
  lock: {
    commonAreas: {
      vendor: {
        lockName: string
      }[]
    }
  }
}

export type TPropertyDevicesVariables = {
  filter?: TQueryFilter<{
    deviceClassTypeId?: number
    deviceTypeId?: number
    propertyId?: number
  }>
}

export type TPropertyDevicesRespone = {
  transactionalDb: {
    allDeviceTypes: {
      nodes: {
        typeId: string
        code: string
        description: string
        classTypeId: string
      }[]
    }

    allDeviceLocationTypes: {
      nodes: {
        typeId: string
        description: string
        code: string
      }[]
    }

    allDeviceInventoryViews: {
      nodes: {
        unitId: string
        deviceTypeId: string
        deviceLocationTypeId: string
        installedDeviceId: string | null
      }[]
    }
  }
}

export type TGetDeviceManufacturersVariables = {
  condition: {
    isActive: boolean
    isDeleted: boolean
  }
}

export type TGetDeviceManufacturersRespone = {
  transactionalDb: {
    manufacturers: {
      nodes: {
        id: string
        code: string
        description: string
      }[]
    }
  }
}

export type TAllUnitsViewVariables = {
  condition: Partial<TUnitView>
  first?: number
  offset?: number
  orderBy?: string[]
  filter: TQueryFilter<TUnitView>
}

export type TAllUnitsViewRespone = {
  db: {
    units: {
      totalCount: number
      nodes: TUnitView[]
    }
  }
}

export type TGetPropertiesWithBuildingsVariables = {
  propertiesCondition: {
    isActive: boolean
    isDeleted: boolean
  }
  buildingsCondition: {
    isActive: boolean
    isDeleted: boolean
  }
}

export type TGetPropertiesWithBuildingsRespone = {
  transactionalDb: {
    properties: {
      nodes: {
        id: TPropertyEntity['propertyId']
        name: TPropertyEntity['propertyName']

        buildings: {
          nodes: {
            id: TBuilding['buildingId']
            name: TBuilding['buildingName']
            number: TBuilding['buildingNumber']
          }[]
        }
      }[]
    }
  }
}
