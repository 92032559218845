import {useCallback, useMemo} from 'react'

import usePortalPersonList from './usePortalPersonList'
import {
  getGreaterThanOrEqualFilter,
  getInFilter,
  getLessThanOrEqualFilter,
  isUnitNumber,
  prepareOrder,
} from '../../functions/filters'
import {QueryOptions} from '../../models'
import {TResidentFilterFields} from '../../views/People/Residents/ResidentsFilter'
import {GET_PORTAL_PERSON_LIST} from '../../data/graphql/queries/people'
import {client} from '../../data/graphql'
import {TPortalPersonListVariables} from '../../data/graphql/queries/people/types'
import {format} from 'date-fns'
import useUserAccess from '../useUserAccess'
import {
  AccessTypeCodeEnum,
  LeaseTypeCodeEnum,
  PersonTypeCodeEnum,
} from '../../data/graphql/queries/enums'

const useResidentsData = (
  searchTerm: string,
  options: Required<QueryOptions<TResidentFilterFields>>,
  isReports?: boolean,
) => {
  const {properties} = useUserAccess()
  const variables = useMemo(() => {
    const {
      unitFilters,
      moveInStart,
      moveInEnd,
      moveOutStart,
      moveOutEnd,
      leaseTypes,
      leaseExpired,
      isPersonAccessActive,
    } = options.filters

    const filter: TPortalPersonListVariables['filter'] = {
      propertyId: {
        in: properties,
      },
      or: unitFilters || [],
      and: [
        {
          personAccessCode: {
            equalTo: AccessTypeCodeEnum.RESIDENT,
          },
          personType: {
            equalTo: PersonTypeCodeEnum.RESIDENT,
          },
        },
      ],
    }

    if (leaseExpired === 'no') {
      filter.and?.push({
        or: [
          {
            isLeaseActive: {
              equalTo: true,
            },
          },
          {
            isLeaseActive: {
              isNull: true,
            },
          },
        ],
      })

      filter.and?.push({
        or: [
          {
            isLeaseExpired: {
              equalTo: false,
            },
          },
          {
            isLeaseExpired: {
              isNull: true,
            },
          },
        ],
      })

      filter.and?.push({
        or: [
          {
            leaseMoveOutDate: getGreaterThanOrEqualFilter(
              format(new Date(), 'yyyy-LL-dd'),
            ),
          },
          {
            leaseMoveOutDate: {
              isNull: true,
            },
          },
        ],
      })

      filter.and?.push({
        or: [
          {
            leaseType: {
              notEqualTo: LeaseTypeCodeEnum.MOVED_OUT,
            },
          },
          {
            leaseType: {
              isNull: true,
            },
          },
        ],
      })
    }

    if (leaseExpired === 'yes') {
      filter.and?.push({
        or: [
          {
            leaseType: {
              equalTo: LeaseTypeCodeEnum.MOVED_OUT,
            },
          },
          {
            isLeaseActive: {
              equalTo: false,
            },
          },
          {
            isLeaseExpired: {
              equalTo: true,
            },
          },
          {
            leaseMoveOutDate: getLessThanOrEqualFilter(format(new Date(), 'yyyy-LL-dd')),
          },
        ],
      })
    }

    if (leaseTypes?.length) {
      filter.and?.push({
        leaseTypeId: getInFilter(leaseTypes),
      })
    }

    if (moveInStart) {
      filter.and?.push({
        leaseMoveInDate: getGreaterThanOrEqualFilter(moveInStart),
      })
    }

    if (moveInEnd) {
      filter.and?.push({
        leaseMoveInDate: getLessThanOrEqualFilter(moveInEnd),
      })
    }

    if (moveOutStart) {
      filter.and?.push({
        leaseMoveOutDate: getGreaterThanOrEqualFilter(moveOutStart),
      })
    }

    if (moveOutEnd) {
      filter.and?.push({
        leaseMoveOutDate: getLessThanOrEqualFilter(moveOutEnd),
      })
    }

    if (searchTerm) {
      filter[isUnitNumber(searchTerm) ? 'unitNumber' : 'personName'] = {
        includesInsensitive: searchTerm,
      }
    }

    const orderBy = prepareOrder(options?.orderBy)

    const condition: TPortalPersonListVariables['condition'] = {
      isLockAccess: true,
    }

    if (isPersonAccessActive && isPersonAccessActive !== 'all' && isReports) {
      condition['isPersonAccessActive'] = isPersonAccessActive === 'active' ? true : false
    }

    if (!isReports) {
      condition['isPersonAccessActive'] = true
    }

    if (!isReports) {
      condition['isPersonUnitActive'] = true
    }

    return {
      first: options.limit,
      offset: options.limit * (options.page - 1),
      filter,
      condition,
      ...(orderBy.length ? {orderBy} : {}),
    }
  }, [options, searchTerm])

  const [residents, response] = usePortalPersonList(variables)

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {
      ...variables,
    }

    if ('first' in variables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    return await client.query({query: GET_PORTAL_PERSON_LIST, variables: copiedVariables})
  }, [variables])

  return {
    residents,
    response,
    variables,
    queryForDownloadTable,
  }
}

export default useResidentsData
