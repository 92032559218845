import {useMemo} from 'react'
import useUserEmployee from './useUserEmployee'
import useCurrentProfile from './useCurrentProfile'
import {DepartmentCodeEnum} from '../data/graphql/queries/enums'

const useRestrictedFeatures = () => {
  const profile = useCurrentProfile()
  const employee = useUserEmployee()
  const departamentCode = employee.data?.departmentCode

  const features = useMemo(() => {
    return {
      masterPin: profile.isDevAdmin || departamentCode === DepartmentCodeEnum.APTHUB_MGR,
    }
  }, [profile.isDevAdmin, departamentCode])

  return {
    features,
    loading: employee.loading,
  }
}

export default useRestrictedFeatures
