import React, {useMemo} from 'react'
import './ResidentsOverview.style.scss'
import {capitalize, isDateInRange, toCommonDateFormat} from '../../functions'
import Badge from '../../components/Badge'
import Card from '../../components/Card/Card'
import Section from '../../components/Grid/Section'
import {useNavigate, useSearchParams} from 'react-router-dom'
import DataGrid from '../../components/DataGrid'
import CellWithAvatar from '../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import usePortalPersonList from '../../hooks/data/usePortalPersonList'
import {BadgeProps} from '../../components/Badge/Badge'
import {useQueryOptions} from '../../hooks/useQueryOptions'
import {TPortalPersonListVariables} from '../../data/graphql/queries/people/types'
import CrashScreen from '../../views/ScreenCrash/CrashScreen'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary'
import TableFooter from '../../components/TableFooter/TableFooter'
import Paginator from '../../components/Paginator/Paginator'
import TableNumberOfItems from '../../components/TabelCountItems/TableNumberOfItems'
import {getResidentTypeByCode} from '../../functions/types.function'
import {AccessTypeCodeEnum, PersonTypeCodeEnum} from '../../data/graphql/queries/enums'

type TGuestRow = {
  personAccessId: number
  name: JSX.Element | string
  type: JSX.Element | string
  personTypeCode: string
  active: JSX.Element | string
  deactive: JSX.Element | string
  status: JSX.Element | string
}

const GuestsInformation = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const unitId = params.get('unitId')

  const {queryOptions, onChangeNumberOfItems, upsertQueryOptions} = useQueryOptions({
    limit: 10,
    page: 1,
    orderBy: ['UNIT_NUMBER_ASC'],
    searchTerm: '',
    filters: {},
  })

  const variables = useMemo<TPortalPersonListVariables>(() => {
    const filter: {[key: string]: any} = {
      personType: {
        notEqualTo: PersonTypeCodeEnum.EMPLOYEE,
      },
      or: [
        {
          personAccessCode: {
            equalTo: AccessTypeCodeEnum.GUEST,
          },
        },
        {
          personAccessCode: {
            equalTo: AccessTypeCodeEnum.PIN_CODE,
          },
        },
      ],
    }

    return {
      condition: {
        isPersonAccessActive: true,
        unitId: unitId ? +unitId : -1,
      },
      filter,
    }
  }, [unitId])

  const [allGuestsList, response] = usePortalPersonList(variables)

  function getStatusBadge(status: string) {
    let theme: BadgeProps['theme']

    switch (status) {
      case 'active':
        theme = 'info'
        break
      case 'inactive':
        theme = 'dark'
        break
      default:
        theme = 'dark'
        break
    }

    return (
      <Badge theme={theme} size={'sm'}>
        {capitalize(status)}
      </Badge>
    )
  }

  const guestsTableData = useMemo((): TGuestRow[] => {
    if (!allGuestsList) {
      return []
    }

    const isAccessActive = (accessStartDate?: string, accessEndDate?: string) => {
      if (!accessStartDate || !accessEndDate) {
        return true
      }

      return isDateInRange(new Date(), accessStartDate, accessEndDate)
    }

    return allGuestsList.map(
      ({
        personName,
        residentType,
        lockScheduleEndDt,
        lockScheduleStartDt,
        personType,
        personAccessId,
      }) => {
        const status = isAccessActive(lockScheduleStartDt, lockScheduleEndDt)
          ? 'active'
          : 'inactive'

        return {
          personAccessId,
          name: <CellWithAvatar name={personName} />,
          type: capitalize(getResidentTypeByCode(residentType)?.description),
          personTypeCode: personType,
          active: toCommonDateFormat(lockScheduleStartDt) || '—',
          deactive: toCommonDateFormat(lockScheduleEndDt) || '—',
          status: getStatusBadge(status),
        }
      },
    )
  }, [allGuestsList])

  const openGuestDetails = (index: number) => {
    const {personAccessId} = guestsTableData[index]

    navigate(`/people/guests/${personAccessId}`)
  }

  const itemCount = response.data?.transactionalDb?.allPortalPersonViews?.totalCount || 0

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <Section>
        <h4 className='black desktop-h4-semibold-22'>Guests</h4>
        <Card padding='sm'>
          <DataGrid
            selectableRows
            columns={[
              {key: 'name', name: 'Name'},
              {key: 'type', name: 'Type'},
              {key: 'active', name: 'Active On'},
              {key: 'deactive', name: 'Deactive On'},
              {key: 'status', name: 'Status'},
            ]}
            minHeight
            rows={guestsTableData}
            onRowSelect={openGuestDetails}
          />
        </Card>
        <TableFooter itemCount={itemCount}>
          <Paginator
            itemCount={itemCount}
            perPage={queryOptions.limit as number}
            currentPage={queryOptions.page as number}
            onPageChange={p =>
              upsertQueryOptions({
                ...queryOptions,
                page: p as number,
              })
            }
          />
          <TableNumberOfItems
            value={queryOptions.limit}
            onValueChange={onChangeNumberOfItems}
          />
        </TableFooter>
      </Section>
    </ErrorBoundary>
  )
}

export default GuestsInformation
