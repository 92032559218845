import {useMemo} from 'react'
import LocalStorageUtils from '../../functions/localStorage.functions'
import {PersonTypeCodeEnum} from '../../data/graphql/queries/enums'

const usePersonTypes = () => {
  const PersonTypeIds = useMemo<Record<PersonTypeCodeEnum, number>>(() => {
    const types = LocalStorageUtils.getItem('personTypes')

    const getId = (code: PersonTypeCodeEnum) => {
      return types?.byCode[code]?.id || -1
    }

    return {
      [PersonTypeCodeEnum.UNKNOWN]: getId(PersonTypeCodeEnum.UNKNOWN),
      [PersonTypeCodeEnum.TENANT]: getId(PersonTypeCodeEnum.TENANT),
      [PersonTypeCodeEnum.RESIDENT]: getId(PersonTypeCodeEnum.RESIDENT),
      [PersonTypeCodeEnum.VENDOR]: getId(PersonTypeCodeEnum.VENDOR),
      [PersonTypeCodeEnum.EMPLOYEE]: getId(PersonTypeCodeEnum.EMPLOYEE),
      [PersonTypeCodeEnum.CONTRACTOR]: getId(PersonTypeCodeEnum.CONTRACTOR),
      [PersonTypeCodeEnum.PROPERTY]: getId(PersonTypeCodeEnum.PROPERTY),
      [PersonTypeCodeEnum.GUEST]: getId(PersonTypeCodeEnum.GUEST),
      [PersonTypeCodeEnum.BUILDING]: getId(PersonTypeCodeEnum.BUILDING),
      [PersonTypeCodeEnum.SELF_GUIDED]: getId(PersonTypeCodeEnum.SELF_GUIDED),
      [PersonTypeCodeEnum.DEV_ADMIN]: getId(PersonTypeCodeEnum.DEV_ADMIN),
    }
  }, [])

  return {PersonTypeIds}
}

export default usePersonTypes
