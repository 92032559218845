import {gql} from '@apollo/client'

export const ADD_BUILDING_ACCOUNT = gql`
  mutation AddBuildingAccount($input: AddBuildingAccountInput!) {
    utility {
      addBuildingAccount(input: $input) {
        ... on AddBuildingAccountSuccess {
          buildingPersonId
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`

export const STORE_TENANT_SECRET = gql`
  mutation StoreTenantSecret($input: StoreTenantSecretInput!) {
    utility {
      storeTenantSecret(input: $input) {
        ... on Success {
          code
          message
          status
          source
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`

export const STORE_BRIVO_TOKEN = gql`
  mutation GetAndStoreRefreshToken($input: GetAndStoreRefreshTokenInput!) {
  lock {
    getAndStoreRefreshToken(input: $input) {
      ... on Success {
        code
        message
        status
        source
      }
      ... on CommonError {
        code
        message
        status
        source
      }
    }
  }
}
`
