import {gql} from '@apollo/client'

export const GET_PORTAL_PEOPLE_COUNT = gql`
  query GetPortalPeopleCount(
    $condition: PortalPersonViewCondition, 
    $filter: PortalPersonViewFilter, 
  ) {
    transactionalDb {
      people: allPortalPersonViews(
        condition: $condition,
        filter: $filter, 
      ) {
        totalCount
      }
    }
  }
`

export const GET_PORTAL_PERSON_LIST = gql`
  query AllPortalPersonViews(
    $condition: PortalPersonViewCondition, 
    $first: Int, 
    $offset: Int, 
    $filter: PortalPersonViewFilter, 
    $orderBy: [PortalPersonViewsOrderBy!]
  ) {
    transactionalDb {
      allPortalPersonViews(
        condition: $condition, 
        first: $first, 
        offset: $offset, 
        filter: $filter, 
        orderBy: $orderBy
      ) {
        totalCount
        nodes {
          personProfileId
          personAccessId
          tenantUuid
          idpTenantId
          personId
          personType
          personName
          residentTypeId
          residentType
          propertyId
          propertyName
          buildingId
          buildingNumber
          buildingName
          unitId
          unitNumber
          unitTypeId
          unitType
          personAccessCode
          personAccessDescription
          invitedId
          invitedByPersonName
          email
          mobilePhone
          lockScheduleType
          lockScheduleEndDt
          lockScheduleStartDt
          lockScheduleDays
          lockScheduleAccessHours
          isPersonAccessActive
          isPersonUnitActive
          isPersonActive
          
          leaseId
          leaseMoveInDate
          leaseMoveOutDate
          leaseType
          leaseTypeId
          isLeaseExpired
          isLeaseActive
        }
      }
    }
  }
`

export const GET_ALL_VENDORS = gql`
  query Vendors($first: Int, $offset: Int, $condition: VendorCondition, $filter: VendorFilter, $orderBy: [VendorsOrderBy!]) {
    transactionalDb {
      allVendors(first: $first, offset: $offset, condition: $condition, filter: $filter, orderBy: $orderBy) {
        nodes {
          vendorId
          vendorName
          isActive
        }
        totalCount
      }
    }
  }
`

export const GET_VENDOR = gql`
  query VendorByVendorId($vendorId: BigInt!) {
    transactionalDb {
      vendorByVendorId(vendorId: $vendorId) {
        vendorId
        isActive
        vendorName
        contactPerson: personByContactPersonId {
          workPhone
          email
        }
        addressByAddressId {
          address1
          address2
          city
          zipCode
          stateByStateId {
            code
            description
          }
        }
      }
    }
  }
`

export const GET_PERSON_ACCESSES_BY_ID = gql`
  query PersonAccessesByPersonId($personId: BigInt!, $condition: PersonAccessCondition) {
    transactionalDb {
      personByPersonId(personId: $personId) {
        firstName
        lastName
        mobilePhone
        email
        miscInfo
        isIdentityCreated
        personAccessesByPersonId(condition: $condition) {
          nodes {
            propertyId
            buildingId
            unitId
            buildingByBuildingId {
              buildingName
            }
            miscInfo
            personAccessTypeId
            isActive
          }
        }
      }
    }
  }
`

export const GET_SERVICE_PERSON_ACCESSES = gql`
  query ServicePersonAccesses(
    $accessesCondition: PersonAccessCondition, 
    $profilesCondition: PersonProfileCondition
    $personId: BigInt!
  ) {
    db: transactionalDb {
      accesses: allPersonAccesses(condition: $accessesCondition) {
        nodes {
          propertyId
          buildingId
          unitId
          unitByUnitId {
            unitNumber
          }
          buildingByBuildingId {
            buildingName
          }
          miscInfo
          isActive
          personAccessTypeId
          personProfileId
        }
      }

      person: personByPersonId(personId: $personId) {
        id: personId
        miscInfo
        isIdentityCreated
      }
        
      profiles: allPersonProfiles(condition: $profilesCondition) {
        nodes {
          personTypeId
          personProfileId
        }
      }
    }
  }
`

export const GET_PROPERTY_ID_BY_PERSON_ID = gql`
  query AllPersonEmployees($filter: PersonEmployeeFilter) {
    transactionalDb {
      allProperties(condition: {isActive: true}) {
        nodes {
          propertyId
        }
      }
      allPersonEmployees(filter: $filter) {
        nodes {
          propertyId
        }
      }
    }
  }
`

export const GET_LEASE_FOR_RESIDENT = gql`
  query GetResidentLeaseOverview(
    $leaseId: BigInt!, 
    $unitId: BigInt!, 
    $condition: PortalPersonViewCondition, 
    $personProfileId: BigInt!
    $orderBy: [PortalPersonViewsOrderBy!]
  ) {
    db: transactionalDb {
      resident: personProfileByPersonProfileId(personProfileId: $personProfileId) {
        personId,
        person: personByPersonId {
          firstName
          lastName
          isIdentityCreated
        },
        residentType: residentTypeByResidentTypeId {
          code
          description
        }
      }
      people: allPortalPersonViews(condition: $condition, orderBy: $orderBy) {
        nodes {
          personId
          personName
          personProfileId
          isPersonAccessActive
        }
      }
      unit: unitByUnitId(unitId: $unitId) {
        floorPlan: floorPlanByFloorPlanId {
          name
          description
        }
      },
      lease: leaseByLeaseId(leaseId: $leaseId) {
        currentBalance
        rentAmount
        paymentDueDate
        leaseBeginDate
        leaseEndDate
        totalPaid
        lateDayOfMonth
        leaseHolderId
        type: leaseTypeByLeaseTypeId {
          code
          description
        }
      }
    }
  }
`

export const GET_VENDOR_USERS_VIEW = gql`
  query AllVendorUserViews(
    $condition: VendorUserViewCondition, 
    $orderBy: [VendorUserViewsOrderBy!], 
    $offset: Int, 
    $first: Int, 
    $filter: VendorUserViewFilter
  ) {
    transactionalDb {
      allVendorUserViews(
        condition: $condition, 
        orderBy: $orderBy, 
        offset: $offset, 
        first: $first, 
        filter: $filter
      ) {
        nodes {
          vendorId
          personId
          name
          mobilePhone
          lockUserId
          isDeleted
          isActive
          email
          personProfileId
        }
        totalCount
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`

export const GET_ALL_EMPLOYEES = gql`
  query AllPortalEmployeeViews(
    $first: Int, $offset: Int,
   $filter: PortalEmployeeViewFilter, 
   $orderBy: [PortalEmployeeViewsOrderBy!],
   $condition: PortalEmployeeViewCondition
   ) {
    transactionalDb {
      allPortalEmployeeViews(
        first: $first, 
        offset: $offset, 
        filter: $filter, 
        orderBy: $orderBy, 
        condition: $condition
      ) {
        totalCount
        nodes {
          personProfileId
          employeeName
          departmentCode
          personEmployeeId
          departmentCode
          departmentDescription
          category
          categoryDescription
          propertyId
          propertyName
          hireDate
          terminationDate
          personId
        }
      }
    }
  }
`

export const GET_EMPLOYEE = gql`
  query EmployeeDetails(
    $personEmployeeId: BigInt!,
    $managersCondition: PersonEmployeeCondition, 
    $residentsCondition: PersonAccessCondition, 
    $staffCondition: PersonEmployeeCondition, 
    $profilesCondition: PersonProfileCondition
    ) {
    transactionalDb {
      employee: personEmployeeByPersonEmployeeId(personEmployeeId: $personEmployeeId) {
        workEmail
        isActive
        person: personByPersonId {
          firstName
          lastName
          mobilePhone
          miscInfo
          isIdentityCreated

          profiles: personProfilesByPersonId(condition: $profilesCondition) {
            nodes {
              personProfileId
              propertyId
              personTypeId
              residentTypeId
            }
          }
        }
        departmentCode
        departmentDescription
        hireDate
        personId

        property: propertyByPropertyId {
            propertyId
            propertyName
            manager: personEmployeesByPropertyId(condition: $managersCondition, first: 1) {
              nodes {
                person: personByPersonId {
                  firstName
                  lastName
                }
              }
            }

            address: addressByAddressId {
              city
              zipCode
              state: stateByStateId {
                code
              }
            }

            residents: personAccessesByPropertyId(condition: $residentsCondition) {
              totalCount
            }
              
            staff: personEmployeesByPropertyId(condition: $staffCondition) {
              totalCount
            }
          }
      }
    }
  }
`

export const GET_ALL_NOTES = gql`
  query AllPersonAccesses($first: Int, $offset: Int, $filter: PersonAccessFilter, $condition: PersonAccessCondition) {
    transactionalDb {
      allPersonAccesses(first: $first, offset: $offset, filter: $filter, condition: $condition) {
        totalCount
        nodes {
          unitId
          personId
          personAccessId
          personByPersonId {
            firstName
            lastName
          }
          notes
          modifiedDt
        }
      }
    }
  }
`

export const GET_PERSON_DEVICE_ACCESSES = gql`
  query GetPersonDeviceAccesses($condition: PersonAccessCondition) {
    transactionalDb {
      allPersonAccesses(condition: $condition) {
        nodes {
          propertyId
          installedDeviceId
          personAccessTypeId
          profile: personProfileByPersonProfileId {
            id: personProfileId
            personTypeId
          }
          personByPersonId {
            miscInfo
          }
        }
      }
    }
  }
`

export const GET_UNITS_INVITES_AND_DEVICES = gql`
  query TransactionalDb(
    $invitesFilter: AppInviteFilter, 
    $devicesFilter: InstalledDeviceFilter, 
    $devicesCondition: InstalledDeviceCondition
  ) {
    transactionalDb {
      allAppInvites(filter: $invitesFilter) {
        nodes {
          email
          mobilePhone
          miscInfo
        }
      }
      allInstalledDevices(filter: $devicesFilter, condition: $devicesCondition) {
        nodes {
          unitId
          deviceTypeId
          installedDeviceId
          deviceLocationTypeId
        }
      }
    }
  }
`

export const GET_ALL_VENDORS_COMPANY_VIEWS = gql`
query AllVendorsCompanyViews($orderBy: [VendorCompanyViewsOrderBy!], $condition: VendorCompanyViewCondition, $filter: VendorCompanyViewFilter, $first: Int, $offset: Int) {
  transactionalDb {
    allVendorCompanyViews(orderBy: $orderBy, condition: $condition, filter: $filter, first: $first, offset: $offset) {
      nodes {
        activeDate
        deactiveDate
        isVendorActive
        isActive
        propertyName
        isVendorDeleted
        vendorId
        vendorName
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
}
`

export const GET_TRANSACTION_RETRY_INFO = gql`
 query GetAsyncTransaction($asyncTransactionId: BigInt!) {
    transactionalDb {
      transaction: asyncTransactionByAsyncTransactionId(asyncTransactionId: $asyncTransactionId) {
        retryInfo
      }
    }
  }
`

export const GET_ASYNC_TRANSACTIONS = gql`
 query GetAsyncTransactions(
  $orderBy: [AsyncTransactionViewsOrderBy!]
  $first: Int,
  $offset: Int,
  $condition: AsyncTransactionViewCondition!,
  $filter: AsyncTransactionViewFilter!
  ) {
  transactionalDb {
    allAsyncTransactionViews(
      first: $first, 
      offset: $offset, 
      orderBy: $orderBy, 
      condition: $condition, 
      filter: $filter
    ) {
      totalCount
      nodes {
        id: asyncTransactionId
        asyncTransactionId
        initiatorPersonId 
        targetPersonId
        targetPersonName
        vendorId
        installedDeviceId
        buildingId
        propertyId
        propertyName
        unitId
        unitNumber
        status
        pinOperationType
        pinAccessType
        pinAccessTimes
        pinAccessRecurrence
        createdDt
      }
    }
  }
} 
`

export const GET_PERSON_BY_ID = gql`
 query GetPersonById($personId: BigInt!) {
    transactionalDb {
      person: personByPersonId(personId: $personId) {
        id: personId
        firstName
        lastName
        email
        mobilePhone
        miscInfo
        isIdentityCreated
      }
    }
  }
`

export const GET_PERSON_ACCESS_BY_ID = gql`
  query PersonAccessById($personAccessId: BigInt!) {
    transactionalDb {
      personAccess: personAccessByPersonAccessId(personAccessId: $personAccessId) {
        miscInfo
        isActive
        personId
        personAccessTypeId
        notes
        building: buildingByBuildingId {
          property: propertyByPropertyId {
            propertyName
          } 
        }
        unit: unitByUnitId {
          unitId
          unitNumber
        }
        profile: personProfileByPersonProfileId {
          id: personProfileId
          personType: personTypeByPersonTypeId {
            description
          }
        }

        person: personByPersonId {
          firstName
          lastName

          invitedBy: personByInvitedId {
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const GET_BUILDING_ACCOUNT_SECRET = gql`
  query GetBuildingPersonSecret($input: GetBuildingPersonSecretInput!) {
    utility {
      getBuildingPersonSecret(input: $input) {
        ... on GetPersonSecretSuccess {
          personSecret
        }
        ... on CommonError {
          code
          message
          status
          source
        }
      }
    }
  }
`

export const GET_VENDOR_IDS_BY_PERSON_VENDORS = gql`
  query GetVendorsByPersonVendors($filter: PersonVendorFilter) {
    transactionalDb {
      allPersonVendors(filter: $filter) {
        nodes {
          personId
          vendorId
        }
      }
    }
  }
`

export const GET_PENDING_VENDOR_INVITES = gql`
  query GetPendingVendorInvites($filter: AppInviteFilter, $orderBy: [AppInvitesOrderBy!]) {
    transactionalDb {
      invites: allAppInvites(filter: $filter, orderBy: $orderBy) {
        nodes {
          id: appInviteId
          email
          mobilePhone
          firstName
          lastName
          expirationDt
          miscInfo
        }
      }
    }
  }
`

export const GET_PERSON_PROFILES = gql`
  query AllPersonProfiles(
    $condition: PersonProfileCondition, 
    $filter: PersonProfileFilter
  ) {
    db: transactionalDb {
      profiles: allPersonProfiles(condition: $condition, filter: $filter) {
        nodes {
          tenantUuid
          propertyId
          personProfileId
          personTypeId
          residentTypeId
        }
      }
    }
  }
`

export const GET_RESIDENT_INVITES_LIST = gql`
  query GetResidentInvitesList(
    $first: Int,
    $offset: Int,
    $condition: ResidentInviteViewCondition, 
    $filter: ResidentInviteViewFilter,
    $orderBy: [ResidentInviteViewsOrderBy!]
  ) {
    db: transactionalDb {
      residents: allResidentInviteViews(
        condition: $condition, 
        filter: $filter,
        first: $first, 
        offset: $offset,
        orderBy: $orderBy
      ) {
        totalCount
        nodes {
          personId
          personProfileId
          residentTypeCode
          personType
          personName
          mobilePhone
          email
          propertyName
          personAccessType
          unitNumber
          unitId
          moveInDate
          moveOutDate
          propertyId
          buildingId
          buildingName
        }
      }
    }
  }
`

export const GET_IMPORT_FAILURES = gql`
  query GetAllPersonIngestIssues(
    $first: Int, 
    $offset: Int, 
    $orderBy: [PersonIngestIssueViewsOrderBy!], 
    $filter: PersonIngestIssueViewFilter, 
    $condition: PersonIngestIssueViewCondition
  ) {
    db: transactionalDb {
      ingestIssues: allPersonIngestIssueViews(
        filter: $filter, 
        first: $first, 
        offset: $offset, 
        orderBy: $orderBy, 
        condition: $condition
      ) {
        totalCount
        nodes {
          personIngestIssueId
          personName
          sourceName
          mobilePhone
          propertyId
          propertyName
          mobilePhoneIsMissing
          mobilePhoneIsDuped
          email
          emailIsMissing
          emailIsDuped
          unitNumber
        }
      }
    }
  }
`
