import {useCallback, useEffect, useState, useContext} from 'react'
import './Units.style.scss'

import {useNavigate, useParams} from 'react-router-dom'
import DataGrid from '../../../components/DataGrid'
import Column from '../../../components/Grid/Column'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import Paginator from '../../../components/Paginator'
import Panel from '../../../components/Panel'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import Search from '../../../layouts/People/Search/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import {formatMoney, toCommonDateFormat} from '../../../functions'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import UnitsFilters, {TUnitsFilterFields} from './UnitsFilter'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import useTableSort from '../../../hooks/useTableSort'
import useAllUnitsView from '../../../hooks/data/useAllUnitsView'
import {TAllUnitsViewRespone} from '../../../data/graphql/queries/properties/types'

type TUnitRow = {
  unitId: string
  unitNumber: string
  residentName: React.ReactNode
  floorplan: string
  unitDescription: string
  buildingName: string
  rentDue: string
  leaseStart: string
  leaseEnd: string
}

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'unitNumber:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Asc'},
  {sortKey: 'unitNumber:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Desc'},
  {
    sortKey: 'residentName:asc',
    value: 'LEASEHOLDER_NAME_ASC',
    label: 'Resident Name Asc',
  },
  {
    sortKey: 'residentName:desc',
    value: 'LEASEHOLDER_NAME_DESC',
    label: 'Resident Name Desc',
  },
  {sortKey: 'floorplan:asc', value: 'FLOOR_PLAN_NAME_ASC', label: 'Floorplan Asc'},
  {sortKey: 'floorplan:desc', value: 'FLOOR_PLAN_NAME_DESC', label: 'Floorplan Desc'},
  {sortKey: 'buildingName:asc', value: 'BUILDING_NAME_ASC', label: 'Building Name Asc'},
  {
    sortKey: 'buildingName:desc',
    value: 'BUILDING_NAME_DESC',
    label: 'Building Name Desc',
  },
  {sortKey: 'rentDue:asc', value: 'RENT_AMOUNT_ASC', label: 'Rent Due Asc'},
  {sortKey: 'rentDue:desc', value: 'RENT_AMOUNT_DESC', label: 'Rent Due Desc'},
  {sortKey: 'leaseStart:asc', value: 'LEASE_BEGIN_DATE_ASC', label: 'Lease Start Asc'},
  {sortKey: 'leaseStart:desc', value: 'LEASE_BEGIN_DATE_DESC', label: 'Lease Start Desc'},
  {sortKey: 'leaseEnd:asc', value: 'LEASE_END_DATE_ASC', label: 'lease End Asc'},
  {sortKey: 'leaseEnd:desc', value: 'LEASE_END_DATE_DESC', label: 'lease End Desc'},
]

const Units = () => {
  const {setQuery} = useContext(ExportTableContext)
  const params = useParams<{propertyId: string}>()
  const propertyId = params.propertyId ? +params.propertyId : -1
  const navigate = useNavigate()

  const [units, setUnits] = useState<TUnitRow[]>([])

  const {
    queryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    onChangeNumberOfItems,
    setQueryOptions,
  } = useQueryOptions<TUnitsFilterFields>({
    page: 1,
    orderBy: ['UNIT_NUMBER_ASC'],
    searchTerm: '',
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])
  const unitsView = useAllUnitsView(debouncedSearchTerm, queryOptions)

  useEffect(() => {
    const units = unitsView.data.map(unit => prepareData(unit))
    setUnits(units)
  }, [unitsView.data])

  const prepareData = useCallback(
    (
      unit: TAllUnitsViewRespone['db']['units']['nodes'][number],
      forTable?: boolean,
    ): TUnitRow => {
      const residentName = unit.leaseholderName.trim() || '—'

      const data = {
        unitId: unit.unitId.toString() || '—',
        unitNumber: unit.unitNumber || '—',
        residentName: forTable ? residentName : <CellWithAvatar name={residentName} />,
        floorplan: unit.floorPlanName || '—',
        unitDescription: unit.unitTypeDescription || '—',
        buildingName: unit.buildingName || '—',
        rentDue: formatMoney(unit.rentAmount) || '—',
        leaseStart: toCommonDateFormat(unit.leaseBeginDate) || '—',
        leaseEnd: toCommonDateFormat(unit.leaseEndDate) || '—',
      }

      return data
    },
    [],
  )

  const dataForTableQuery = useCallback(async () => {
    // try {
    //   const {data} = await unitsView.queryForDownloadTable()
    //   const logs = data?.transactionalDb?.allUnits?.nodes
    //   const tableData = logs.map(unit => Object.values(prepareData(unit, true)))
    //   tableData.unshift([
    //     'Unit',
    //     'Resident',
    //     'Floorplan',
    //     'Description',
    //     'Building Name',
    //     'Rent Due',
    //     'Lease Start',
    //     'Lease End',
    //   ])
    //   return tableData
    // } catch (error) {
    //   console.error(error)
    // }
  }, [unitsView.variables])

  useEffect(() => {
    // setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryOptions, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const onSubmitFilter = useCallback((filters: TUnitsFilterFields) => {
    setQueryOptions(prev => ({
      ...prev,
      page: 1,
      filters: filters,
    }))
  }, [])

  const onTypeSearchField = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions({
        page: 1,
        searchTerm,
      })
    },
    [upsertQueryOptions],
  )

  const onPressRow = useCallback(
    (index: number) => {
      if (!units[index]) return
      navigate(`/properties/${propertyId}/units/${units[index].unitId}`)
    },
    [units],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [upsertQueryOptions],
  )

  const onChangeSortOrder = value => {
    tableSort.setSortValue(value)
    upsertQueryOptions(prev => ({...prev, orderBy: [value]}))
  }

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Units'} data-testid={'UnitsView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search units'
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onTypeSearchField}
                  />
                  <SearchFilters filter={UnitsFilters} onSubmit={onSubmitFilter} />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                </Search>

                <Panel theme={'white'}>
                  <DataGrid
                    selectableRows
                    selectedColumn={tableSort.column}
                    selectedColumnChange={tableSort.setSortColumn}
                    order={tableSort.order}
                    onRowSelect={onPressRow}
                    loading={unitsView.loading}
                    perPage={queryOptions.limit}
                    columns={[
                      {name: 'Unit', key: 'unitNumber', sortable: true},
                      {name: 'Resident', key: 'residentName', sortable: true},
                      {name: 'Floorplan', key: 'floorplan', sortable: true},
                      {name: 'Description', key: 'unitDescription'},
                      {name: 'Building Name', key: 'buildingName', sortable: true},
                      {name: 'Rent Due', key: 'rentDue', sortable: true},
                      {name: 'Lease Start', key: 'leaseStart', sortable: true},
                      {name: 'Lease End', key: 'leaseEnd', sortable: true},
                    ]}
                    rows={units}
                  />
                </Panel>
                <TableFooter itemCount={unitsView.totalCount} loading={unitsView.loading}>
                  <Paginator
                    itemCount={unitsView?.totalCount || 0}
                    perPage={queryOptions.limit}
                    currentPage={queryOptions.page}
                    onPageChange={onChangePage}
                  />
                  <TableNumberOfItems
                    value={queryOptions.limit}
                    onValueChange={onChangeNumberOfItems}
                  />
                </TableFooter>
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default Units
