import {useCallback} from 'react'
import {useMutation} from '@apollo/client'
import {
  AccessScheduleTypes,
  TGrantCommonAreaAccessResponse,
  TGrantCommonAreaAccessVariables,
  TGrantFullAccessToLocksResponse,
  TGrantFullAccessToLocksVariables,
  TGrantLimitedAccessToLocksResponse,
  TGrantLimitedAccessToLocksVariables,
  TRevokeAccessToLocksResponse,
  TRevokeAccessToLocksVariables,
  TRevokeCommonAreaAccessResponse,
  TRevokeCommonAreaAccessVariables,
  TSetLockPinsResponse,
  TSetLockPinsVariables,
} from '../data/graphql/mutations/lock/types'
import {
  GRANT_COMMON_AREA_ACCESSES,
  GRANT_FULL_ACCESS_TO_LOCKS,
  GRANT_LIMITED_ACCESS_TO_LOCKS,
  REVOKE_ACCESSES_TO_LOCKS,
  REVOKE_COMMON_AREA_ACCESSES,
  SET_LOCK_PINS,
} from '../data/graphql/mutations/lock'
import useToast from '../hooks/useToast'
import {
  DeviceMaker,
  isCommonError,
  TCommonError,
  YaleUserType,
} from '../data/graphql/types'
import {scheduleToAccessParams} from '../functions/lock.functions'
import useServicePersonAccesses from '../hooks/data/useServicePersonAccesses'
import {TAccessType} from '../components/AccessSchedule/AccessSchedule'
import {GET_ASYNC_TRANSACTIONS} from '../data/graphql/queries/people'
import {client} from '../data/graphql'
import {capitalize} from '../functions'
import {PersonTypeCodeEnum, ResidentTypeCodeEnum} from '../data/graphql/queries/enums'

type TLockAccess = {
  accessLevel?: TAccessType
  lockId: string
  access?: {
    schedule?: string
    accessType?: AccessScheduleTypes
    deviceId?: string
    endTime?: string
    startTime?: string
  }
}

type TCommonAreaAccess = {
  accessType: TAccessType
  propertyId: string
  startDate?: string
  endDate?: string
}

type TLockRemoveAccess = {
  installedDeviceId: number
}

type FullYaleAccessData = {
  accesses: TGrantFullAccessToLocksVariables['input']['lockAccesses']
  personType: TGrantFullAccessToLocksVariables['input']['personType']
  residentType: TGrantFullAccessToLocksVariables['input']['residentType']
}

type LimitedYaleAccessData = {
  accesses: TGrantLimitedAccessToLocksVariables['input']['lockAccessRules']
  personType: TGrantLimitedAccessToLocksVariables['input']['personType']
  residentType: TGrantLimitedAccessToLocksVariables['input']['residentType']
}

type UpdateAccessesData = {
  accesses: TLockAccess[]
  personType: TGrantLimitedAccessToLocksVariables['input']['personType']
  residentType: TGrantLimitedAccessToLocksVariables['input']['residentType']
}

type LockAccessesProps = {
  personId: number
  profileId: number
  personType: PersonTypeCodeEnum
}

const useLockAccesses = ({personId, profileId, personType}: LockAccessesProps) => {
  const {showErrorToast, showInfoToast} = useToast()

  const {person, response: personAccessResponse} = useServicePersonAccesses({
    personId,
  })

  const yaleUserId = person?.miscInfo?.yaleLock?.userId || ''

  const [setLockPinsRequest, setLockPinsResponse] = useMutation<
    TSetLockPinsResponse,
    TSetLockPinsVariables
  >(SET_LOCK_PINS, {
    refetchQueries: [GET_ASYNC_TRANSACTIONS],
    onCompleted: response => {
      handleYalePinsResponse(response.lock.setLockPins)
    },
  })

  const [grantLimitedAccessesRequest, grantLimitedAccessesResponse] = useMutation<
    TGrantLimitedAccessToLocksResponse,
    TGrantLimitedAccessToLocksVariables
  >(GRANT_LIMITED_ACCESS_TO_LOCKS, {
    onError() {
      showErrorToast('Grant units access', 'Failed to grant user units access')
    },
    onCompleted(response) {
      handleYaleLocksResponse('grant', response.lock?.grantLimitedAccessToLocks)
    },
  })

  const [grantFullAcccessesRequest, grantFullAccessesResponse] = useMutation<
    TGrantFullAccessToLocksResponse,
    TGrantFullAccessToLocksVariables
  >(GRANT_FULL_ACCESS_TO_LOCKS, {
    onError() {
      showErrorToast('Grant units access', 'Failed to grant user units access')
    },
    onCompleted(response) {
      handleYaleLocksResponse('grant', response.lock?.grantFullAccessToLocks)
    },
  })

  const [revokeLockAccessesRequest, revokeLockAccessesResponse] = useMutation<
    TRevokeAccessToLocksResponse,
    TRevokeAccessToLocksVariables
  >(REVOKE_ACCESSES_TO_LOCKS, {
    onError() {
      showErrorToast('Revoke units access', 'Failed to revoke user units access')
    },
    onCompleted(response) {
      handleYaleLocksResponse('revoke', response.lock?.revokeAccessToSelectedLocks)
    },
  })

  const [grantCommonAreaAccessesRequest, grantCommonAreaAccessesResponse] = useMutation<
    TGrantCommonAreaAccessResponse,
    TGrantCommonAreaAccessVariables
  >(GRANT_COMMON_AREA_ACCESSES)

  const [revokeCommonAreaAccessesRequest, revokeCommonAreaAccessesResponse] = useMutation<
    TRevokeCommonAreaAccessResponse,
    TRevokeCommonAreaAccessVariables
  >(REVOKE_COMMON_AREA_ACCESSES)

  function handleYalePinsResponse(data: TCommonError | {failed: any[]}) {
    if (!isCommonError(data)) {
      if (data.failed.length) {
        showErrorToast('Pin code access', `Some the pin codes has not been updated`)
      } else {
        showInfoToast('Pin code access', 'All pin codes have been submitted')
      }
    }
  }

  function handleYaleLocksResponse(
    action: 'grant' | 'revoke',
    data: TCommonError | {failed: any[]},
  ) {
    const capitalizeAction = capitalize(action)
    const passiveAction = action === 'revoke' ? 'revoked' : 'granted'
    const title = `${capitalizeAction} unit accesses`

    if (!isCommonError(data)) {
      if (data?.failed?.length) {
        showErrorToast(title, `One or more access could not be ${passiveAction}`)
      } else {
        showInfoToast(title, `Accesses to units locks has been ${passiveAction}`)
      }
    }
  }

  const groupAppAccesses = (accesses: TLockAccess[]) => {
    const limitedLockAccesses: TGrantLimitedAccessToLocksVariables['input']['lockAccessRules'] =
      []
    const fullLockAccesses: TGrantFullAccessToLocksVariables['input']['lockAccesses'] = []

    accesses.forEach(({access}) => {
      if (!access?.deviceId) {
        return
      }

      if (access.accessType === AccessScheduleTypes.ALWAYS) {
        fullLockAccesses.push({
          yaleDeviceId: access.deviceId,
          userType: YaleUserType.USER,
        })
      } else {
        limitedLockAccesses.push({
          schedule: access.schedule || '',
          accessType: access.accessType,
          deviceId: access.deviceId || '',
          endTime: access.endTime || '',
          startTime: access.startTime || '',
        })
      }
    })

    return {limited: limitedLockAccesses, full: fullLockAccesses}
  }

  const grantFullYaleAccess = useCallback(
    async ({accesses, personType, residentType}: FullYaleAccessData) => {
      if (!accesses.length) {
        return Promise.resolve([])
      }

      const failedLockYaleIds: string[] = []

      try {
        const response = await grantFullAcccessesRequest({
          variables: {
            input: {
              userId: yaleUserId,
              deviceMaker: DeviceMaker.YALE,
              lockAccesses: accesses,
              personType,
              residentType,
            },
          },
        })

        const data = response.data?.lock.grantFullAccessToLocks

        if (isCommonError(data)) {
          throw new Error('Failed to grant full units locks accesses')
        }

        data?.failed.forEach(({yaleDeviceId}) => failedLockYaleIds.push(yaleDeviceId))
      } catch (e) {
        accesses.forEach(({yaleDeviceId}) => failedLockYaleIds.push(yaleDeviceId))
      }

      return failedLockYaleIds
    },
    [grantFullAcccessesRequest, yaleUserId],
  )

  const grantLimitedYaleAccess = useCallback(
    async ({accesses, residentType, personType}: LimitedYaleAccessData) => {
      if (!accesses.length) {
        return Promise.resolve([])
      }

      const failedLockYaleIds: string[] = []

      try {
        const response = await grantLimitedAccessesRequest({
          variables: {
            input: {
              residentType,
              personType,
              lockAccessRules: accesses,
              userId: yaleUserId,
              deviceMaker: DeviceMaker.YALE,
            },
          },
        })

        const data = response.data?.lock.grantLimitedAccessToLocks

        if (isCommonError(data)) {
          throw new Error('Failed to grant limited unit locks accesses')
        }

        data?.failed.forEach(({yaleDeviceId}) => failedLockYaleIds.push(yaleDeviceId))
      } catch (e) {
        accesses.forEach(({deviceId}) => failedLockYaleIds.push(deviceId))
      }

      return failedLockYaleIds
    },
    [grantLimitedAccessesRequest, yaleUserId],
  )

  const updatePinAccesses = useCallback(
    async (accesses: TLockAccess[], unverifiedUserYaleUserId?: string) => {
      if (!accesses.length) {
        return Promise.resolve()
      }

      const locksToSet = accesses.map(({lockId, access, accessLevel}) => {
        const yaleUserType =
          accessLevel === 'pin'
            ? YaleUserType.UNVERIFIED
            : access?.schedule
            ? YaleUserType.LIMITED
            : YaleUserType.USER
        const pinAccessData = scheduleToAccessParams(access?.schedule)

        return {
          yaleUserType,
          deviceId: lockId,
          ...pinAccessData,
        }
      })

      return await setLockPinsRequest({
        variables: {
          input: {
            locksToSet,
            userId: unverifiedUserYaleUserId ?? yaleUserId,
            deviceMaker: DeviceMaker.YALE,
            personType: personType,
            residentType: ResidentTypeCodeEnum.NOT_A_RESIDENT,
          },
        },
      })
    },
    [setLockPinsRequest, yaleUserId, personType],
  )

  const updateAppAccesses = useCallback(
    async ({
      accesses,
      personType,
      residentType,
    }: UpdateAccessesData): Promise<string[]> => {
      if (!accesses.length) {
        return Promise.resolve([])
      }

      const groupedAccesses = groupAppAccesses(accesses)

      const [fullAccessResponse, limitedAccessResponse] = await Promise.all([
        grantFullYaleAccess({accesses: groupedAccesses.full, personType, residentType}),
        grantLimitedYaleAccess({
          accesses: groupedAccesses.limited,
          personType,
          residentType,
        }),
      ])

      return [...fullAccessResponse, ...limitedAccessResponse]
    },
    [grantFullYaleAccess, grantLimitedYaleAccess],
  )

  const revokeLockAccesses = useCallback(
    async (accesses: TLockRemoveAccess[] = []) => {
      if (!accesses.length || !profileId) {
        return Promise.resolve(null)
      }

      const installedDeviceIds = accesses.map(({installedDeviceId}) => installedDeviceId)

      const response = await revokeLockAccessesRequest({
        variables: {
          input: {
            personProfileId: Number(profileId),
            installedDeviceIds,
            deviceMaker: DeviceMaker.YALE,
          },
        },
      })

      client.refetchQueries({
        include: [GET_ASYNC_TRANSACTIONS],
      })

      return response
    },
    [profileId, revokeLockAccessesRequest],
  )

  const grantCommonAreaAccesses = useCallback(
    async (
      access: TCommonAreaAccess | null,
      unverifiedPersonId?: number,
    ): Promise<true | never> => {
      if (!access) {
        return true
      }

      let personId = unverifiedPersonId || 0

      if (!personId && person?.id) {
        personId = Number(person.id)
      }

      const buildGrantRequest = ({
        propertyId,
        startDate,
        endDate,
        accessType,
      }: TCommonAreaAccess) =>
        grantCommonAreaAccessesRequest({
          variables: {
            input: {
              personId,
              propertyId: Number(propertyId),
              effectiveFrom: startDate,
              effectiveTo: endDate,
              deviceMaker: DeviceMaker.BRIVO,
              enableAppAccess: accessType === 'app',
              personType: personType,
              residentType: ResidentTypeCodeEnum.NOT_A_RESIDENT,
            },
          },
        })

      const response = await Promise.allSettled([buildGrantRequest(access)])

      const numberOfFailedRequests = response.filter(
        ({status}) => status === 'rejected',
      ).length

      if (numberOfFailedRequests === response.length) {
        showErrorToast('Common area access', 'Failed to grant common areas access')
        throw new Error('Failed to grant common areas access', {cause: 'requestFailure'})
      } else if (numberOfFailedRequests > 0) {
        showErrorToast(
          'Common area access',
          'Failed to grant access to some common areas',
        )
        throw new Error('Failed to grant access to some common areass', {
          cause: 'partialRequestFailure',
        })
      }

      showInfoToast('Common area access', 'Common areas access has been granted')

      return true
    },
    [
      grantCommonAreaAccessesRequest,
      person?.id,
      personType,
      showErrorToast,
      showInfoToast,
    ],
  )

  const revokeCommonAreaAccesses = useCallback(
    async (propertyId: string) => {
      if (!person?.id || !propertyId) {
        return Promise.resolve(null)
      }

      const buildRevokeRequest = (propertyId: string) =>
        revokeCommonAreaAccessesRequest({
          variables: {
            input: {
              personId: Number(person?.id),
              propertyId: Number(propertyId),
              deviceMaker: DeviceMaker.BRIVO,
            },
          },
        })

      const response = await Promise.allSettled([buildRevokeRequest(propertyId)])

      const numberOfFailedRequests = response.filter(
        ({status}) => status === 'rejected',
      ).length

      if (numberOfFailedRequests === response.length) {
        showErrorToast('Common areas access', 'Failed to revoke common areas accesses')
      } else if (numberOfFailedRequests > 0) {
        showErrorToast(
          'Common areas access',
          'Failed to revoke some common areas accesses',
        )
      } else {
        showInfoToast('Common areas access', 'Accesses to common areas have been revoked')
      }

      return response
    },
    [revokeCommonAreaAccessesRequest, person?.id, showErrorToast, showInfoToast],
  )

  return {
    loading:
      setLockPinsResponse.loading ||
      grantLimitedAccessesResponse.loading ||
      revokeLockAccessesResponse.loading ||
      revokeCommonAreaAccessesResponse.loading ||
      grantCommonAreaAccessesResponse.loading ||
      grantFullAccessesResponse.loading,
    revokeLockAccesses,
    revokeCommonAreaAccesses,
    grantCommonAreaAccesses,
    updatePinAccesses,
    updateAppAccesses,
    refreshPersonAccesses: personAccessResponse.refetch,
  }
}

export default useLockAccesses
