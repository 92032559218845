import './Residents.style.scss'
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'

import Column from '../../../components/Grid/Column'
import Panel from '../../../components/Panel'
import Row from '../../../components/Grid/Row'
import Section from '../../../components/Grid/Section'
import DataGrid from '../../../components/DataGrid'
import Paginator from '../../../components/Paginator'
import {useNavigate} from 'react-router-dom'
import CellWithAvatar from '../../../components/DataGrid/CellWithAvatar/CellWithAvatar'
import Search from '../../../layouts/People/Search'
import SearchFilterInput from '../../../layouts/People/Search/SearchFilterInput'
import SearchFilters from '../../../layouts/People/Search/SearchFilters'
import SearchSortBy from '../../../layouts/People/Search/SearchSortBy'
import Card from '../../../components/Card/Card'
import PersonCard from '../../../components/PersonCard/PersonCard'
import SearchLayoutTogggle from '../../../layouts/People/Search/SearchLayoutTogggle'
import useIsInViewport from '../../../hooks/useIsInViewport'
import {SortOptionsItem, useQueryOptions} from '../../../hooks/useQueryOptions'
import {ExportTableContext} from '../../../components/ExportTable/ExportTableContextProvider'
import {toCommonDateFormat} from '../../../functions'
import ErrorBoundary from '../../../components/ErrorBoundary/ErrorBoundary'
import CrashScreen from '../../ScreenCrash/CrashScreen'
import useResidentsData from '../../../hooks/data/useResidentsData'
import ResidentsFilters, {TResidentFilterFields} from './ResidentsFilter'
import TableFooter from '../../../components/TableFooter/TableFooter'
import TableNumberOfItems from '../../../components/TabelCountItems/TableNumberOfItems'
import {useGetEmptyTableMessage} from '../../../hooks/filters/useGetEmptyTableMessage'
import useTableSort from '../../../hooks/useTableSort'
import DeactivatePersonCell from '../../../components/DeactivatePersonCell'
import {LeaseTypeCodeEnum} from '../../../data/graphql/queries/enums'

export interface ResidentPreview {
  name: string
  nameWithAvatar: React.ReactNode
  property: string
  building: string
  unit: string
  unitId: number
  moveIn: string
  moveOut: string
  leaseType: React.ReactNode
  leaseId: string | null
  expired: React.ReactNode
  id: number
  profileId: number
}

const PAGE_SIZE = 10

const sortOptions: Required<SortOptionsItem>[] = [
  {sortKey: 'nameWithAvatar:asc', value: 'PERSON_NAME_ASC', label: 'Name Asc'},
  {sortKey: 'nameWithAvatar:desc', value: 'PERSON_NAME_DESC', label: 'Name Desc'},
  {sortKey: 'unit:asc', value: 'UNIT_NUMBER_ASC', label: 'Unit Asc'},
  {sortKey: 'unit:desc', value: 'UNIT_NUMBER_DESC', label: 'Unit Desc'},
  {sortKey: 'building:asc', value: 'BUILDING_NAME_ASC', label: 'Building Asc'},
  {sortKey: 'building:desc', value: 'BUILDING_NAME_DESC', label: 'Building Desc'},
  {sortKey: 'property:asc', value: 'PROPERTY_NAME_ASC', label: 'Property Asc'},
  {sortKey: 'property:desc', value: 'PROPERTY_NAME_DESC', label: 'Property Desc'},
  {sortKey: 'moveIn:asc', value: 'LEASE_MOVE_IN_DATE_ASC', label: 'Move In Asc'},
  {sortKey: 'moveIn:desc', value: 'LEASE_MOVE_IN_DATE_DESC', label: 'Move In Desc'},
  {sortKey: 'moveOut:asc', value: 'LEASE_MOVE_OUT_DATE_ASC', label: 'Move Out Asc'},
  {sortKey: 'moveOut:desc', value: 'LEASE_MOVE_OUT_DATE_DESC', label: 'Move Out Desc'},
  {sortKey: 'leaseType:asc', value: 'LEASE_TYPE_ASC', label: 'Lease Type Asc'},
  {sortKey: 'leaseType:desc', value: 'LEASE_TYPE_DESC', label: 'Lease Type Desc'},
]

const leaseTypesMap = {
  [LeaseTypeCodeEnum.UNKNOWN]: 'Unknown',
  [LeaseTypeCodeEnum.FIRST_LEASE]: 'First Lease',
  [LeaseTypeCodeEnum.MOVED_OUT]: 'Moved Out',
  [LeaseTypeCodeEnum.NOTICE_GIVEN]: 'Notice Given',
  [LeaseTypeCodeEnum.RENEWAL]: 'Renewal',
}

const Residents = () => {
  const navigate = useNavigate()
  const {setQuery} = useContext(ExportTableContext)
  const lastCardRef = useRef<HTMLDivElement | null>(null)
  const isInViewport = useIsInViewport(lastCardRef.current)
  const [residents, setResidents] = useState<ResidentPreview[]>([])
  const [layoutType, setLayoutType] = useState<'table' | 'grid'>('table')

  const {
    queryOptions,
    setQueryOptions,
    upsertQueryOptions,
    debouncedSearchTerm,
    onChangeNumberOfItems,
  } = useQueryOptions<TResidentFilterFields>({
    page: 1,
    orderBy: ['LEASE_MOVE_IN_DATE_ASC'],
    searchTerm: '',
    filters: {},
  })

  const tableSort = useTableSort(sortOptions, queryOptions.orderBy[0])

  const {
    residents: accessesList,
    response: residentsResponse,
    variables: queryVariables,
    queryForDownloadTable,
  } = useResidentsData(debouncedSearchTerm, queryOptions)

  useEffect(() => {
    if (isInViewport) {
      upsertQueryOptions(prev => ({
        page: prev.page + 1,
      }))
    }
  }, [isInViewport])

  useEffect(() => {
    const initialRequestData = accessesList.map(resident => {
      const leaseTypeLabel = resident.leaseType ? leaseTypesMap[resident.leaseType] : '—'
      const isMovedOut = resident.leaseType === LeaseTypeCodeEnum.MOVED_OUT
      const moveOutDatePassed =
        resident.leaseMoveOutDate &&
        new Date(resident.leaseMoveOutDate).getTime() < Date.now()

      const shouldBeRevoked = moveOutDatePassed && isMovedOut

      return {
        id: resident.personId,
        profileId: Number(resident.personProfileId),
        name: resident.personName,
        nameWithAvatar: <CellWithAvatar name={resident.personName} />,
        property: resident.propertyName,
        building: resident.buildingName,
        unit: resident.unitNumber,
        unitId: resident.unitId,
        leaseId: resident.leaseId,

        moveIn: toCommonDateFormat(resident.leaseMoveInDate) || '—',
        moveOut: toCommonDateFormat(resident.leaseMoveOutDate) || '—',
        leaseType: (
          <span className={isMovedOut ? 'moved-out-label' : ''}>{leaseTypeLabel}</span>
        ),
        expired: (
          <div className='revoke-cell'>
            {shouldBeRevoked ? (
              <DeactivatePersonCell
                personProfileId={Number(resident.personProfileId)}
                onRevoke={residentsResponse.refetch}
              >
                <span className={'expired-lease-label'}>Yes</span>
              </DeactivatePersonCell>
            ) : (
              <span className={'expired-lease-label'}>No</span>
            )}
          </div>
        ),
      }
    })

    if (layoutType === 'grid') {
      setResidents(prev => {
        const offset = residentsResponse.variables?.offset || 0
        const oldList = offset > 0 ? prev : []

        return [...oldList, ...initialRequestData]
      })
    } else {
      setResidents(initialRequestData)
    }
  }, [accessesList, layoutType, residentsResponse.refetch])

  const onChangeSortOrder = (value: string) => {
    tableSort.setSortValue(value)
    upsertQueryOptions({orderBy: [value]})
  }

  const onChangeSearchQuery = useCallback(
    (searchTerm: string) => {
      upsertQueryOptions({
        searchTerm,
        page: 1,
      })
    },
    [upsertQueryOptions],
  )

  const onChangePage = useCallback(
    (page: number) => {
      upsertQueryOptions({
        page: page,
      })
    },
    [upsertQueryOptions],
  )

  const onToggleLayoutType = useCallback(
    (layout: 'table' | 'grid') => {
      setLayoutType(layout)

      upsertQueryOptions({
        page: 1,
      })
    },
    [upsertQueryOptions],
  )

  const onPressPerson = useCallback(
    (person?: ResidentPreview) => {
      if (person?.profileId && person.unitId && person.leaseId) {
        navigate(
          `/people/residents/${person.profileId}/overview?unitId=${person.unitId}&leaseId=${person.leaseId}`,
        )
      }
    },
    [navigate],
  )

  const onPressRow = useCallback(
    (index: number) => {
      onPressPerson(residents[index])
    },
    [residents, onPressPerson],
  )

  const onSubmitFilter = useCallback(
    (filters: TResidentFilterFields) => {
      setQueryOptions(prev => ({
        ...prev,
        filters,
        page: 1,
      }))
    },
    [setQueryOptions],
  )

  const dataForTableQuery = useCallback(async () => {
    try {
      const {data} = await queryForDownloadTable()

      const residents = data?.transactionalDb.allPortalPersonViews.nodes

      const tableData = residents.map(resident =>
        Object.values({
          name: resident.personName || '—',
          property: resident.propertyName || '—',
          building: resident.buildingName || '—',
          unit: resident.unitNumber || '—',

          moveIn: toCommonDateFormat(resident.lockScheduleStartDt) || '—',
          moveOut: toCommonDateFormat(resident.lockScheduleStartDt) || '—',
          leaseType: resident.leaseType ? leaseTypesMap[resident.leaseType] : '—',
          expired: resident.isLeaseExprired ? 'Yes' : 'No',
        }),
      )

      tableData.unshift([
        'Name',
        'Property',
        'Building',
        'Unit',
        'Move In',
        'Move Out',
        'Lease Type',
        'Expired',
      ])

      return tableData
    } catch (error) {
      console.error(error)
    }
  }, [queryForDownloadTable])

  useEffect(() => {
    setQuery(dataForTableQuery as () => Promise<string[][]>)
  }, [dataForTableQuery, queryVariables, setQuery])

  useEffect(() => {
    if (queryOptions.orderBy?.[0] !== tableSort.value) {
      upsertQueryOptions(prev => ({...prev, orderBy: [tableSort.value]}))
    }
  }, [tableSort.value])

  const hasMorePages =
    (residentsResponse.data?.transactionalDb.allPortalPersonViews.totalCount || 0) >
    PAGE_SIZE * queryOptions.page

  const emptyResidentsTable = useGetEmptyTableMessage(queryOptions, {
    query: `Sorry, no matches found by "${queryOptions.searchTerm}".`,
    filter: `Sorry, no matches found by your filters.`,
    filtersAndQuery: `Sorry, no matches found by "${queryOptions.searchTerm}" and filters.`,
    default: 'Residents table is empty...',
  })

  const itemCount =
    residentsResponse.data?.transactionalDb.allPortalPersonViews.totalCount || 0

  return (
    <ErrorBoundary fallback={CrashScreen}>
      <div className={'Residents'} data-testid={'ResidentsView'}>
        <Section>
          <Row>
            <Column>
              <>
                <Search>
                  <SearchFilterInput
                    placeholder='Search residents'
                    value={queryOptions.searchTerm || ''}
                    onValueChange={onChangeSearchQuery}
                  />
                  <SearchFilters
                    filter={ResidentsFilters}
                    initialValue={queryOptions.filters}
                    onSubmit={onSubmitFilter}
                  />
                  <SearchSortBy
                    value={queryOptions.orderBy[0] || ''}
                    options={sortOptions}
                    onChange={onChangeSortOrder}
                  />
                  <SearchLayoutTogggle value={layoutType} onChange={onToggleLayoutType} />
                </Search>

                {layoutType === 'grid' ? (
                  <Row>
                    <>
                      {residents.map((data, index) => (
                        <Column key={index} sm={'6'} md={'4'}>
                          <PersonCard person={data} onClick={onPressPerson} />
                        </Column>
                      ))}

                      {hasMorePages ? (
                        <Column sm={'6'} md={'4'}>
                          <Card
                            ref={lastCardRef}
                            className={'d-flex align-center justify-center'}
                          >
                            <h5>Loading ...</h5>
                          </Card>
                        </Column>
                      ) : null}
                    </>
                  </Row>
                ) : (
                  <Panel theme={'white'}>
                    <DataGrid
                      selectableRows
                      selectedColumn={tableSort.column}
                      selectedColumnChange={tableSort.setSortColumn}
                      order={tableSort.order}
                      onRowSelect={onPressRow}
                      emptyTableComponent={emptyResidentsTable}
                      loading={residentsResponse.loading}
                      columns={[
                        {name: 'Name', key: 'nameWithAvatar', sortable: true},
                        {name: 'Property', key: 'property', sortable: true},
                        {name: 'Building', key: 'building', sortable: true},
                        {name: 'Unit', key: 'unit', sortable: true},
                        {name: 'Move In', key: 'moveIn', sortable: true},
                        {name: 'Move Out', key: 'moveOut', sortable: true},
                        {name: 'Lease Type', key: 'leaseType', sortable: true},
                        {name: 'Expired', key: 'expired'},
                      ]}
                      rows={residents}
                    />
                  </Panel>
                )}

                {layoutType !== 'grid' ? (
                  <TableFooter itemCount={itemCount} loading={residentsResponse.loading}>
                    <Paginator
                      itemCount={itemCount}
                      perPage={queryOptions.limit}
                      currentPage={queryOptions.page}
                      onPageChange={onChangePage}
                    />
                    <TableNumberOfItems
                      value={queryOptions.limit}
                      onValueChange={onChangeNumberOfItems}
                    />
                  </TableFooter>
                ) : null}
              </>
            </Column>
          </Row>
        </Section>
      </div>
    </ErrorBoundary>
  )
}

export default Residents
