// components/ProgressBar/ProgressBar.tsx

import React, {FC} from 'react'
import './ProgressBar.style.scss'

export interface ProgressBarProps {
  progress?: number
  label?: string
  showPercent?: boolean
}

const defaultProps: ProgressBarProps = {
  showPercent: false,
}

const ProgressBar: FC<ProgressBarProps> = (props: ProgressBarProps) => {
  props = {...defaultProps, ...props}

  return (
    <>
      {props.label && <div className={'label'}>{props.label}</div>}
      <div className={'progress-bar'} data-testid={'ProgressBar'}>
        {props.showPercent && <div className={'percent'}>{props.progress}%</div>}
        <div className={'progress'} style={{width: props.progress + '%'}}>
          {props.showPercent && <div className={'percent'}>{props.progress}%</div>}
        </div>
      </div>
    </>
  )
}

export default ProgressBar
