import {useCallback, useMemo} from 'react'

import usePortalPersonList from './usePortalPersonList'
import {QueryOptions} from '../../models'
import {
  getGreaterThanOrEqualFilter,
  getLessThanOrEqualFilter,
  isUnitNumber,
  prepareOrder,
} from '../../functions/filters'
import {TGuestsFilterFields} from '../../views/People/Guests/GuestsFilter'
import {GET_PORTAL_PERSON_LIST} from '../../data/graphql/queries/people'
import {client} from '../../data/graphql'
import useUserAccess from '../useUserAccess'
import {AccessTypeCodeEnum, PersonTypeCodeEnum} from '../../data/graphql/queries/enums'

const useGuestsData = (
  searchTerm: string,
  options: Required<QueryOptions<TGuestsFilterFields>>,
  isReports?: boolean,
) => {
  const {properties} = useUserAccess()
  const variables = useMemo(() => {
    const {
      unitFilters,
      activeEnd,
      activeStart,
      deactiveEnd,
      deactiveStart,
      accessType,
      isPersonAccessActive,
    } = options.filters

    const filterValues: Array<{[key: string]: any}> = []

    const filter: {[key: string]: any} = {
      propertyId: {
        in: properties,
      },
      personAccessCode: {
        in: [AccessTypeCodeEnum.GUEST, AccessTypeCodeEnum.PIN_CODE],
      },
      personType: {
        in: [PersonTypeCodeEnum.GUEST, PersonTypeCodeEnum.RESIDENT],
      },
      or: unitFilters || [],
    }

    if (activeStart) {
      filterValues.push({
        lockScheduleStartDt: getGreaterThanOrEqualFilter(activeStart),
      })
    }

    if (activeEnd) {
      filterValues.push({
        lockScheduleStartDt: getLessThanOrEqualFilter(activeEnd),
      })
    }

    if (deactiveStart) {
      filterValues.push({
        lockScheduleEndDt: getGreaterThanOrEqualFilter(deactiveStart),
      })
    }

    if (deactiveEnd) {
      filterValues.push({
        lockScheduleEndDt: getLessThanOrEqualFilter(deactiveEnd),
      })
    }

    if (accessType) {
      filterValues.push({
        lockScheduleType: {
          in: accessType,
        },
      })
    }

    if (searchTerm) {
      if (!isUnitNumber(searchTerm)) {
        filter.or = [
          {
            personName: {
              includesInsensitive: searchTerm,
            },
          },
          {
            invitedByPersonName: {
              includesInsensitive: searchTerm,
            },
          },
        ]
      } else {
        filter.unitNumber = {
          includesInsensitive: searchTerm,
        }
      }
    }

    if (filterValues.length) {
      filter.and = filterValues
    }

    const orderBy = prepareOrder(options?.orderBy)
    const condition = {}

    if (isPersonAccessActive && isPersonAccessActive !== 'all' && isReports) {
      condition['isPersonAccessActive'] = isPersonAccessActive === 'active' ? true : false
    }

    if (!isReports) {
      condition['isPersonAccessActive'] = true
    }

    return {
      first: options.limit,
      offset: options.limit * (options.page - 1),
      filter,
      condition,
      ...(orderBy.length ? {orderBy} : {}),
    }
  }, [options, searchTerm])

  const [guests, response] = usePortalPersonList(variables)

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: Partial<any> = {
      ...variables,
      filter: {
        ...variables?.filter,
        and: [
          ...(variables?.filter?.and || []),
          // TODO: propertyId for imported residents is 0, should be fixed on BE
          // {
          //   propertyId: {
          //     in: userAccess.properties,
          //   },
          // },
        ],
      },
    }
    if ('first' in copiedVariables) {
      delete copiedVariables['first']
    }

    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }

    return await client.query({
      query: GET_PORTAL_PERSON_LIST,
      variables: copiedVariables,
    })
  }, [variables])

  return {
    guests,
    response,
    variables,
    queryForDownloadTable,
  }
}

export default useGuestsData
