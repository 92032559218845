import {useMutation} from '@apollo/client'
import {useCallback, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {INVITE_VENDOR} from '../../data/graphql/mutations/people'
import {
  TInviteVendorResponse,
  TInviteVendorVariables,
} from '../../data/graphql/mutations/people/types'
import useLockAccesses from '../../hooks/useLockAccesses'
import useToast from '../../hooks/useToast'
import {TVendorUserLayoutSubmitData} from '../../layouts/VendorUserLayout/VendorUserLayout'

import useInstallerVendorUserOverview from './useInstallerVendorUserOverview'
import useServiceVendorUserOverview from './useServiceVendorUserOverview'
import useVendorUserContext from '../../layouts/VendorUserLayout/VendorUserContext/useVendorContext'
import {PersonTypeCodeEnum} from '../../data/graphql/queries/enums'

const useVendorUserOverview = () => {
  const navigate = useNavigate()
  const {showToast, showErrorToast, showInfoToast} = useToast()

  const {vendorId} = useParams<{vendorId: string; userId?: string}>()

  const {person, profile} = useVendorUserContext()
  const {cancelInstallationTasks, assignInstallationTasks} =
    useInstallerVendorUserOverview()

  const personId = person ? Number(person.id) : 0

  const serviceVendor = useServiceVendorUserOverview()
  const {refreshPersonAccesses} = useLockAccesses({
    personId,
    profileId: profile ? Number(profile.personProfileId) : 0,
    personType: PersonTypeCodeEnum.VENDOR,
  })

  const [inviteVendorRequest] = useMutation<
    TInviteVendorResponse,
    TInviteVendorVariables
  >(INVITE_VENDOR)

  const [loading, setLoading] = useState(false)

  const isVendorIdentityCreated = !!person?.isIdentityCreated
  const yaleUserId = person?.miscInfo?.yaleLock?.userId

  const handleVendorInvite = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      const invitee: TInviteVendorVariables['input']['invitee'] = {
        email: input.invitee.email,
        firstName: input.invitee.firstName,
        lastName: input.invitee.lastName,
        mobilePhone: input.invitee.mobilePhone,
        vendorId: Number(vendorId),
      }

      const payload: TInviteVendorVariables['input'] = {
        invitee,
        sender: input.sender,
        requestedThermostatAccesses: [],
        requestedLockAccesses: [],
        tasks: {
          serviceTasks: input.tasks.serviceTasks,
          installationTasks: input.tasks.installationTasks.devicesToInstall,
        },
      }

      payload.requestedLockAccesses = input.requestedLockAccesses.reduce<
        {
          installedDeviceId: number
          schedule?: string
        }[]
      >((result, {access, accessLevel, installedDeviceId}) => {
        if (accessLevel === 'app') {
          result.push({
            installedDeviceId,
            schedule: access?.schedule,
          })
        }
        return result
      }, [])

      if (input.requestedPropertyCommonAreaAccess) {
        const {propertyId, startDate, endDate} = input.requestedPropertyCommonAreaAccess

        payload.requestedCommonAreaLockAccess = {
          effectiveFrom: startDate,
          effectiveTo: endDate,
          propertyId: Number(propertyId),
        }
      }

      return await inviteVendorRequest({
        variables: {
          input: payload,
        },
      })
    },
    [inviteVendorRequest, vendorId],
  )

  const onServiceVendorSubmit = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      let personIdToRefetch = personId
      let personProfileIdToRefetch = profile?.personProfileId

      const hasChanges =
        !!input.requestedLockAccesses.length ||
        !!input.deletedLockAccesses.length ||
        !!input.deletedPropertyCommonAreaAccess.length ||
        !!input.requestedPropertyCommonAreaAccess

      if (!hasChanges) {
        return showToast({
          type: 'info',
          title: 'No changes detected',
        })
      }

      setLoading(true)

      const hasAppAccessGranted = input.requestedLockAccesses.some(
        ({accessLevel}) => accessLevel === 'app',
      )

      await serviceVendor.revokeAccesses(input)

      if (profile && yaleUserId && isVendorIdentityCreated) {
        await Promise.allSettled([
          serviceVendor.grantAppAccesses(input),
          serviceVendor.grantPinAccesses(input),
        ])
      } else {
        if (hasAppAccessGranted) {
          try {
            await handleVendorInvite(input)
            showInfoToast('App invitation', "Invitation has been sent'")
          } catch (e) {
            showErrorToast('App invitation', 'Invitation has not been sent')
          }
        }

        const createPinOnlyUserRequests: ReturnType<
          typeof serviceVendor.createYalePinUser
        >[] = []

        if (input.requestedLockAccesses.length) {
          createPinOnlyUserRequests.push(
            serviceVendor.createYalePinUser(
              input.invitee,
              input.requestedLockAccesses[0].lockId,
            ),
          )
        }

        if (input.requestedPropertyCommonAreaAccess) {
          createPinOnlyUserRequests.push(serviceVendor.createBrivoPinUser(input.invitee))
        }

        const [pinOnlyUser] = await Promise.all(createPinOnlyUserRequests)

        if (pinOnlyUser) {
          personIdToRefetch = Number(pinOnlyUser.personId)
          personProfileIdToRefetch = pinOnlyUser.personProfileId

          await serviceVendor.grantPinAccesses(input, {
            personId: Number(pinOnlyUser.personId),
            personProfileId: Number(pinOnlyUser.personProfileId),
            yaleUserId: pinOnlyUser.yaleUserId,
          })
        }
      }

      await refreshPersonAccesses({
        personId: personIdToRefetch,
        accessesCondition: {
          personId: personId ? Number(personId) : 0,
          isActive: true,
          isDeleted: false,
          personProfileId: personProfileIdToRefetch
            ? Number(personProfileIdToRefetch)
            : 0,
        },
        profilesCondition: {
          personId: personId ? Number(personId) : 0,
          isDeleted: false,
        },
      })

      setLoading(false)

      navigate(`/people/vendors/${vendorId}`)
    },
    [
      personId,
      profile,
      serviceVendor,
      yaleUserId,
      isVendorIdentityCreated,
      refreshPersonAccesses,
      navigate,
      vendorId,
      showToast,
      handleVendorInvite,
      showInfoToast,
      showErrorToast,
    ],
  )

  const onInstallerVendorSubmit = useCallback(
    async (input: TVendorUserLayoutSubmitData) => {
      setLoading(true)

      if (!isVendorIdentityCreated) {
        try {
          await handleVendorInvite(input)
          showInfoToast('App invitation', 'Invitation has been sent')
        } catch (e) {
          console.log(e)
          showErrorToast('App invitation', 'Invitation has not been sent')
        }
      } else {
        await Promise.allSettled([
          assignInstallationTasks(input),
          cancelInstallationTasks(input),
        ])
      }

      setLoading(false)
      navigate(`/people/vendors/${vendorId}`)
    },
    [
      assignInstallationTasks,
      cancelInstallationTasks,
      handleVendorInvite,
      isVendorIdentityCreated,
      navigate,
      showErrorToast,
      showInfoToast,
      vendorId,
    ],
  )

  return {loading, onServiceVendorSubmit, onInstallerVendorSubmit}
}

export default useVendorUserOverview
