import {useCallback, useMemo} from 'react'

import {getInFilter, isUnitNumber} from '../../functions/filters'
import {QueryOptions} from '../../models'
import {useQuery} from '@apollo/client'
import {client} from '../../data/graphql'
import useExcludedBuildingId from '../useExcludedBuildingId'
import {
  TAllUnitsViewRespone,
  TAllUnitsViewVariables,
} from '../../data/graphql/queries/properties/types'
import useCurrentProfile from '../useCurrentProfile'
import {GET_ALL_UNITS_VIEW} from '../../data/graphql/queries/properties'
import useToast from '../useToast'
import useUnitTypes from '../types/useUnitTypes'
import {UnitTypeCodeEnum} from '../../data/graphql/queries/enums'
import {TUnitsFilterFields} from '../../views/PropertyDetails/Units/UnitsFilter'

const useAllUnitsView = (
  searchTerm: string,
  options: Required<QueryOptions<TUnitsFilterFields>>,
) => {
  const {showToast} = useToast()
  const {UnitTypeIds} = useUnitTypes()
  const {propertyId} = useCurrentProfile()
  const devsBuildingId = useExcludedBuildingId()

  const variables = useMemo<TAllUnitsViewVariables>(() => {
    const {unitFilters, floorplan} = options.filters

    const condition: TAllUnitsViewVariables['condition'] = {
      propertyId,
      unitTypeId: UnitTypeIds[UnitTypeCodeEnum.APARTMENT],
    }

    const filter: TAllUnitsViewVariables['filter'] = {
      and: [],
      or: unitFilters || [],
      buildingId: {
        notEqualTo: devsBuildingId,
      },
    }

    if (floorplan?.length) {
      filter.and?.push({floorPlanId: getInFilter(floorplan)})
    }

    if (searchTerm) {
      filter[isUnitNumber(searchTerm) ? 'unitNumber' : 'leaseholderName'] = {
        includesInsensitive: searchTerm,
      }
    }

    return {
      filter,
      first: options.limit,
      offset: options.limit * (options.page - 1),
      condition,
      orderBy: options.orderBy,
    }
  }, [
    options.filters,
    options.limit,
    options.page,
    options.orderBy,
    propertyId,
    UnitTypeIds,
    devsBuildingId,
    searchTerm,
  ])

  const query = useQuery<TAllUnitsViewRespone, TAllUnitsViewVariables>(
    GET_ALL_UNITS_VIEW,
    {
      variables,
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Failed to fetch units list',
          type: 'error',
        })
      },
    },
  )

  const queryForDownloadTable = useCallback(async () => {
    const copiedVariables: TAllUnitsViewVariables = {...variables}
    if ('first' in copiedVariables) {
      delete copiedVariables['first']
    }
    if ('offset' in copiedVariables) {
      delete copiedVariables['offset']
    }
    return await client.query<TAllUnitsViewRespone, TAllUnitsViewVariables>({
      query: GET_ALL_UNITS_VIEW,
      variables: copiedVariables,
    })
  }, [variables])

  const data = useMemo(() => {
    return query?.data?.db?.units.nodes || []
  }, [query?.data?.db?.units.nodes])

  return {
    data,
    query,
    variables,
    loading: query.loading,
    totalCount: query?.data?.db?.units.totalCount || 0,
    queryForDownloadTable,
  }
}

export default useAllUnitsView
