import {AccessTypeCodeEnum} from '../data/graphql/queries/enums'

export function handlePersonAccessCodeGuests(accessCode: AccessTypeCodeEnum) {
  switch (accessCode) {
    case AccessTypeCodeEnum.PIN_CODE:
      return 'Pin Code'
    case AccessTypeCodeEnum.UNKNOWN:
      return 'Unkown'
    default:
      return 'App'
  }
}
