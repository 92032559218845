// components/FlyoutMenu/FlyoutMenu.tsx

import React, {FC, useState} from 'react'
import './FlyoutMenu.style.scss'

export interface FlyoutMenuProps {
  isShown?: boolean
  hide?: () => void
  content?: React.ReactNode
}

const defaultProps: FlyoutMenuProps = {}

export const useFlyoutMenu = () => {
  const [isShown, setIsShown] = useState<boolean>(false)
  const toggle = () => setIsShown(!isShown)
  return {
    isShown,
    toggle,
  }
}

const FlyoutMenu: FC<FlyoutMenuProps> = (props: FlyoutMenuProps) => {
  props = {...defaultProps, ...props}

  const flyoutMenu = (
    <div id={'FlyoutMenu'} data-testid={'FlyoutMenu'}>
      <div className={'gobo'} onClick={props.hide}></div>
      <div className={'flyout-menu'}>{props.content}</div>
    </div>
  )

  return props.isShown ? flyoutMenu : null
}

export default FlyoutMenu
