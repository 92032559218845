import {useMemo} from 'react'
import LocalStorageUtils from '../../functions/localStorage.functions'
import {DeviceClassTypeCodeEnum} from '../../data/graphql/queries/enums'

const useDeviceClassTypes = () => {
  const DeviceClassTypeIds = useMemo<Record<DeviceClassTypeCodeEnum, number>>(() => {
    const types = LocalStorageUtils.getItem('deviceClassTypes')

    const getId = (code: DeviceClassTypeCodeEnum) => {
      return types?.byCode[code]?.id || -1
    }

    return {
      [DeviceClassTypeCodeEnum.UNKNOWN]: getId(DeviceClassTypeCodeEnum.UNKNOWN),
      [DeviceClassTypeCodeEnum.LOCK]: getId(DeviceClassTypeCodeEnum.LOCK),
      [DeviceClassTypeCodeEnum.THERMOSTAT]: getId(DeviceClassTypeCodeEnum.THERMOSTAT),
      [DeviceClassTypeCodeEnum.BRIDGE]: getId(DeviceClassTypeCodeEnum.BRIDGE),
      [DeviceClassTypeCodeEnum.DOOR_SENSE]: getId(DeviceClassTypeCodeEnum.DOOR_SENSE),
    }
  }, [])

  return {DeviceClassTypeIds}
}

export default useDeviceClassTypes
