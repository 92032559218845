import useCurrentProfile from './useCurrentProfile'

/**
 * Hook that returns building id that should be excluded from queries results.
 * If user is in dev mode or is developer admin, it returns -1 (to not exclude any buildings),
 * otherwise it returns the building id from the environment variable.
 */
const useExcludedBuildingId = (): number => {
  const {isDevAdmin} = useCurrentProfile()
  const devModeValue = localStorage.getItem('devMode')
  const isDevMode = devModeValue !== null ? JSON.parse(devModeValue) : false

  const envValue = Number(process.env.REACT_APP_DEVS_BUILDING_ID)

  if (isDevMode || isDevAdmin) {
    return -1
  }

  return envValue
}

export default useExcludedBuildingId
