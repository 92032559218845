import './PendingInvite.style.scss'

import {FC} from 'react'
import Button from '../../../components/Button/Button'

import classNames from 'classnames'
import {TInviteResident} from '../../../data/graphql/queries/entities'
import {DeviceTypeCodeEnum} from '../../../data/graphql/queries/enums'
import useDeviceTypes from '../../../hooks/types/useDeviceTypes'

export type PendingInviteCellProps = {
  index: number
  person: TInviteResident
  invitedEmails: Array<string | null>
  installedDevices: {
    deviceTypeId: string | number
  }[]
  onPress: (index: number) => void
}

const PendingInviteCell: FC<PendingInviteCellProps> = ({
  index,
  person,
  invitedEmails,
  installedDevices,
  onPress,
}) => {
  const {DeviceTypeIds} = useDeviceTypes()
  const lockDeviceTypes = [
    DeviceTypeIds[DeviceTypeCodeEnum.YALE_622],
    DeviceTypeIds[DeviceTypeCodeEnum.YALE_ASSURE_2],
  ]

  const invited = invitedEmails.includes(person.email)
  const hasInstalledLocks = !!installedDevices?.filter(({deviceTypeId}) =>
    lockDeviceTypes.includes(+deviceTypeId),
  )?.length

  const enabledInvite = person.residentTypeCode && person.email && person.mobilePhone

  return (
    <div className='invite-cell'>
      {!hasInstalledLocks && (
        <span className='no-devices-label'>No installed devices</span>
      )}

      {hasInstalledLocks && invited && <span className='invited-label'>Invite sent</span>}

      <Button
        disabled={!enabledInvite || !hasInstalledLocks}
        className={classNames({
          'invite-button': true,
          'invisible-invite-button': !hasInstalledLocks,
        })}
        onClick={() => onPress(index)}
      >
        {invited ? 'Resend' : 'Invite'}
      </Button>
    </div>
  )
}

export default PendingInviteCell
