import {gql} from '@apollo/client'

export const GET_ALL_TYPES = gql`
  query GetAllTypes {
    transactionalDb {
      allPersonTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: personTypeId
        }
      }

      allResidentTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: residentTypeId
        }
      }

      allPersonAccessTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: personAccessTypeId
        }
      }
      
      allServiceTicketStatusTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: serviceTicketStatusTypeId
        }
      }

      allServiceTicketCategoryTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: serviceTicketCategoryTypeId
        }
      }

      allServiceTicketIssueLocationTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: serviceTicketIssueLocationTypeId
        }
      }

      allLeaseTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: leaseTypeId
        }
      }
        
      allSecretTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: secretTypeId
        }
      }

      allDeviceManufacturerTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: deviceManufacturerTypeId
        }
      }

      allDeviceClassTypes(condition: {isDeleted: false}) {
        nodes {
          code
          id: deviceClassTypeId
        }
      }

      allUnitTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: unitTypeId
        }
      }

      allDeviceLocationTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: deviceLocationTypeId
        }
      }

      allDeviceTypes(condition: {isDeleted: false}) {
        nodes {
          code
          description
          id: deviceTypeId
        }
      }
    }
  }
`
export const GET_ALL_BUILDING = gql`
  query Nodes($condition: BuildingCondition) {
    transactionalDb {
      allBuildings(condition: $condition) {
        nodes {
          nodeId
          buildingId
          buildingName
        }
      }
    }
  }
`

export const GET_BUILDINGS_WITH_UNITS = gql`
  query AllBuildingsWithUnits($condition: BuildingCondition, $filter: BuildingFilter) {
    transactionalDb {
      allBuildings(condition: $condition, filter: $filter) {
        nodes {
          buildingId
          buildingName
          unitsByBuildingId {
            nodes {
              unitId
              unitNumber
              unitType: unitTypeByUnitTypeId {
                description  
              }
            }
          }
        }
      }
    }
  }
`

export const GET_ALL_UNITS_FOR_PROPERTY_DETAILS = gql`
  query AllUnits(
    $filter: UnitFilter, 
    $condition: UnitCondition, 
    $offset: Int, 
    $first: Int, 
    $orderBy: [UnitsOrderBy!], 
    $leasesCondition: LeaseCondition
  ) {
    transactionalDb {
      allUnits(
        filter: $filter, 
        condition: $condition, 
        offset: $offset, 
        first: $first, 
        orderBy: $orderBy
      ) {
        totalCount
        nodes {
          unitId
          buildingId
          unitNumber
          unitTypeByUnitTypeId {
            description
          }
          buildingByBuildingId {
            buildingName
          }
          leasesByUnitId(condition: $leasesCondition) {
            nodes {
              leaseEndDate
              leaseBeginDate
              rentAmount

              leaseholder: personByLeaseHolderId {
                firstName
                lastName
              }
            }
          }
          floorPlanByFloorPlanId {
            name
            description
          }
        }
      }
    }
  }
`

export const GET_ALL_UNITS_FOR_OTHER_ACCESS = gql`
  query AllUnits(
    $filter: UnitFilter, 
    $condition: UnitCondition, 
    $offset: Int, 
    $first: Int, 
    $orderBy: [UnitsOrderBy!], 
    $lockActivityLogsByUnitIdOrderBy2: [LockActivityLogsOrderBy!], 
    $lockActivityLogsByUnitIdFilter2: LockActivityLogFilter, 
    $lockActivityLogsByUnitIdFirst2: Int
  ) {
    transactionalDb {
      allUnits(filter: $filter, condition: $condition, offset: $offset, first: $first, orderBy: $orderBy) {
        totalCount
        nodes {
          unitId
          buildingId
          unitNumber
          isActive
          lockActivityLogsByUnitId(orderBy: $lockActivityLogsByUnitIdOrderBy2, filter: $lockActivityLogsByUnitIdFilter2, first: $lockActivityLogsByUnitIdFirst2) {
            nodes {
              timeStamp
            }
          }
        }
      }
    }
  }
`

export const GET_ALL_STATES = gql`
  query AllStates {
    transactionalDb {
      allStates {
        nodes {
          stateId
          code
          description
        }
      }
    }
  }
`

export const GET_PERSON_HOUSE_ACCOUNT = gql`
  query GetAllPersonHouseAccounts($condition: PersonHouseAccountCondition) {
    transactionalDb {
      accounts: allPersonHouseAccounts(condition: $condition) {
        nodes {
          buildingId
          person: personByPersonId {
            id: personId
            email
            mobilePhone
          }
        }
      }
    }
  }
`

export const GET_ALL_TENANTS = gql`
  query GetAllTenants($condition: TenantCondition) {
    db: transactionalDb {
      tenants: allTenants(condition: $condition) {
        nodes {
          tenantId
          tenantUuid
          tenantName
        }
      }
    }
  }
`
