// forms/FilterMenu/FilterMenu.tsx

import React, {FC, useState} from 'react'
import InputText from '../../components/InputText/InputText'
import Button from '../../components/Button'
import ButtonGroup from '../../components/ButtonGroup'
import DebugOutput from '../../components/DebugOutput'
import CheckboxGroup from '../../components/CheckboxGroup'
import InputCheckbox from '../../components/InputCheckbox/InputCheckbox'

export interface FilterMenuProps {
  value?
  valueChange?
}

const defaultProps: FilterMenuProps = {}

const FilterMenuForm: FC<FilterMenuProps> = (props: FilterMenuProps) => {
  props = {...defaultProps, ...props}

  const [formData, setFormData] = useState({
    search: '',
  })

  return (
    <form>
      <InputText
        placeholder={'search'}
        suffixIcon={'search'}
        value={formData.search}
        onValueChange={e => setFormData({...formData, search: e as string})}
      />
      <DebugOutput>{`${formData}`}</DebugOutput>
      <CheckboxGroup>
        <InputCheckbox label={'checkbox a'} />
        <InputCheckbox label={'checkbox b'} />
        <InputCheckbox label={'checkbox c'} />
      </CheckboxGroup>
      <ButtonGroup>
        <Button width={'block'}>Apply Filters</Button>
        <Button theme={'link'} width={'block'}>
          Cancel
        </Button>
      </ButtonGroup>
    </form>
  )
}

export default FilterMenuForm
