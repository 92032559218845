import {useCallback, useEffect, useState} from 'react'
import {CheckboxItem} from '../../components/CollapsibleCheckboxList/CollapsibleCheckboxList'
import useAllTypes from '../data/useAllTypes'
import ObjectUtils from '../../functions/object.functions'

const useLeaseTypeFilter = (initialValue?: number[]) => {
  const allTypes = useAllTypes()
  const [types, setTypes] = useState<CheckboxItem[]>([])

  useEffect(() => {
    const value = ObjectUtils.values(allTypes.value.lease.byId)

    setTypes(
      value.map(item => ({
        id: Number(item?.id),
        name: item?.description || '',
        checked: !!initialValue?.includes(Number(item?.id)),
      })),
    )
  }, [allTypes.value.lease.byId])

  const getSelectedIds = useCallback(() => {
    return types.filter(({checked}) => checked).map(({id}) => +id)
  }, [types])

  const clear = useCallback(() => {
    setTypes(
      types.map(item => ({
        ...item,
        checked: false,
      })),
    )
  }, [types])

  return {types, setTypes, clear, getSelectedIds}
}

export default useLeaseTypeFilter
