import {useQuery} from '@apollo/client'
import useToast from '../useToast'
import {GET_PERSON_PROFILES} from '../../data/graphql/queries/people'
import {
  TGetPersonProfilesResponse,
  TGetPersonProfilesVariables,
} from '../../data/graphql/queries/people/types'
import useUserPersonId from '../useUserPersonId'
import {useMemo} from 'react'
import usePersonTypes from '../types/usePersonTypes'
import {PersonTypeCodeEnum} from '../../data/graphql/queries/enums'

const useAvailableProfiles = () => {
  const {showToast} = useToast()
  const personId = useUserPersonId()
  const {PersonTypeIds} = usePersonTypes()

  const query = useQuery<TGetPersonProfilesResponse, TGetPersonProfilesVariables>(
    GET_PERSON_PROFILES,
    {
      variables: {
        condition: {
          personId: Number(personId),
          isDeleted: false,
        },
        filter: {
          personTypeId: {
            in: [
              PersonTypeIds[PersonTypeCodeEnum.EMPLOYEE],
              PersonTypeIds[PersonTypeCodeEnum.DEV_ADMIN],
            ],
          },
        },
      },
      onError() {
        showToast({
          title: 'Request Error',
          message: 'Failed to fetch your person profiles',
          type: 'error',
        })
      },
    },
  )

  const data = query.data?.db.profiles.nodes

  const properties = useMemo(() => {
    if (!data) {
      return []
    }

    return data.map(({propertyId}) => +propertyId)
  }, [data])

  return {
    loading: query.loading,
    query,
    data,
    properties,
  }
}

export default useAvailableProfiles
